'use strict';
import $mp_user_tags from './mp_user_tags';
import request from './request';

let module_name = 'mp_apps';

export default {
    setTagApps(tag, studio, apps) {
        return new Promise((resolve, reject) => {
            request
                .post('mp_tags', {
                    studio: studio,
                    tag_name: tag,
                    appids: apps
                })
                .then(
                    (data) => {
                        $mp_user_tags.deleteTag(tag).then((data) => {
                            resolve(data);
                        });
                    },
                    (err) => {
                        reject(err);
                    }
                );
        });
    },
    editTag(old_tag_name, old_studio, to_tag_name, to_studio, need_stat) {
        return new Promise((resolve, reject) => {
            request
                .post('edit_mp_tag', {
                    old_tag_name: old_tag_name,
                    old_studio: old_studio,
                    to_tag_name: to_tag_name,
                    to_studio: to_studio,
                    need_stat: need_stat
                })
                .then(
                    (data) => {
                        // $mp_user_tags.deleteTag(tag).then( data=>{
                        resolve(data);
                        // });
                    },
                    (err) => {
                        reject(err);
                    }
                );
        });
    },
    getTagsList() {
        return new Promise((resolve, reject) => {
            $mp_user_tags.getAllTags().then(
                (data) => {
                    let all_user_tags = [];
                    for (let k in data) {
                        all_user_tags.push({
                            index: all_user_tags.length,
                            tag_name: k,
                            studio: '',
                            appids: data[k],
                            desc: k
                        });
                    }
                    request.get('mp_tags', {}).then(
                        (data) => {
                            //resolve(data);
                            data.forEach((tag) => {
                                (tag.index = all_user_tags.length),
                                    (tag.desc =
                                        tag.tag_name + (tag.studio != '' ? `(${tag.studio})` : '')),
                                    all_user_tags.push(tag);
                            });
                            resolve(all_user_tags);
                        },
                        (err) => {
                            reject(err);
                        }
                    );
                },
                (err) => {
                    reject(err);
                }
            );
        }); // Promise
    },
    deleteTag(tag, studio) {
        return new Promise((resolve, reject) => {
            request
                .delete('mp_tags', {
                    tag_name: tag,
                    studio: studio
                })
                .then(
                    (data) => {
                        resolve();
                        $mp_user_tags.deleteTag(tag).then(
                            (data) => {
                                resolve();
                            },
                            (err) => {
                                resolve();
                            }
                        );
                    },
                    (err) => {
                        reject(err);
                    }
                );
        });
    }
};
