"use strict";
import request from '../request'

export default {
    getAllMiniprograms(){
        return new Promise( (resolve, reject) => {
            request.get('miniprgram', {}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    addMiniprogram(params){
        return new Promise( (resolve, reject) => {
            request.post('miniprgram', params).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    }
    
}