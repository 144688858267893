import request from './request'

const appid = 'mox5e6ee02693193b1ba1d1ace9'

export default {

    get_config() {
      return new Promise((resolve, reject) => {

        request.get(`xingzuo_portal/config`).then(data => {
          resolve(data);
        }, err => {
          reject(err)
        });
      });
    },

    set_config(key, value){
      return new Promise((resolve, reject) => {

        request.put(`xingzuo_portal/config/${key}`,value).then(data => {
          resolve(data);
        }, err => {
          reject(err)
        });
      });
    },



    get_permission(){
      return new Promise((resolve, reject) => {

        request.get(`news/permission_studio`).then(data => {
          resolve(data);
        }, err => {
          reject(err)
        });
      });
    },

    get_ads_list() {
        return new Promise((resolve, reject) => {
            
            request.get(`xingzuo_portal/ad`).then(data => {
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    update_ad(id, params){
        return new Promise((resolve, reject) => {

            request.put(`xingzuo_portal/ad/` + id, params).then(data => {
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    delete_ad(id){
        return new Promise((resolve, reject) => {

            request.delete(`xingzuo_portal/ad/` + id).then(data => {
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    create_ad(params) {
        return new Promise((resolve, reject) => {

            request.post(`xingzuo_portal/ad/`, params).then(data => {
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

   

  get_products(offset) {
    return new Promise((resolve, reject) => {
      request.get(`xingzuo_portal/product?limit=5000&offset=${offset}`).then(data => {
        resolve(data)
      }, err => {
        reject(err)
      })
    })
  },
  get_product(id) {
    return new Promise((resolve, reject) => {
      request.get(`xingzuo_portal/product/${id}`).then(data => {
        resolve(data)
      }, err => {
        reject(err)
      })
    })
  },
  delete_product(id) {
    return new Promise((resolve, reject) => {
      request.delete(`xingzuo_portal/product/${id}`).then(data => {
        resolve(data)
      }, err => {
        reject(err)
      })
    })
  },
  edit_product(id, data) {
    return new Promise((resolve, reject) => {
      request.put(`xingzuo_portal/product/${id}`, data).then(data => {
        resolve(data)
      }, err => {
        reject(err)
      })
    })
  },
  create_product(params) {
    return new Promise((resolve, reject) => {
      request.post('xingzuo_portal/product/', params).then(data => {
        resolve(data)
      }, err => {
        reject(err)
      })
    })
  }
}