<template>
    <div id="app">
        <router-view name="blank"></router-view>
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: 'app'
    };
</script>

<style type="text/css">
    /*解决el-table表头错位问题*/
    .el-table th {
        display: table-cell !important;
    }
</style>

<style lang="less">
    * {
        padding: 0;
        margin: 0;
        outline: none;
        box-sizing: border-box;
    }

    #app,
    body,
    html {
        height: 100%;
        font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB',
            'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
    }

    #app {
        background-color: #f8f9fb;
    }

    a {
        color: #3c8dbc;
        text-decoration: none;
    }

    .el-menu .fa {
        vertical-align: middle;
        margin-right: 5px;
        width: 24px;
        text-align: center;
    }

    .el-menu .fa:not(.is-children) {
        font-size: 14px;
    }

    .main {
        display: flex;
        .el-menu:not(.el-menu--collapse) {
            width: 100%;
        }
        .app {
            width: 100%;
            height: calc(~'100vh - 50px');
            top: 50px;
            position: absolute;
            overflow: hidden;
        }
    }

    .chapter {
        font-size: 24px;
        margin-bottom: 10px;
    }

    .mo-cell {
        margin-bottom: 20px;
    }

    .el-form-item {
        margin-bottom: 12px;
    }
    .el-form--label-top .el-form-item__label {
        padding: 0;
    }

    .el-table-column--selection .cell {
        text-align: center;
        padding-left: 0px !important;
        padding-right: 0px;
    }

    .tips-migrated {
        color: red;
        padding: 15px;
        font-size: 1.5em;
    }
</style>
