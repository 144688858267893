"use strict";
import axios from 'axios';
import request from './request'

export default {
    getUserTagList(mpid){
        return new Promise( (resolve, reject) => {
            request.get('user_tag', {
                'mpid': mpid
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
}