/*
仅开发用的页面
 */
export default {
    path: '/console',
    title: '研发控制台',
    component: resolve => require(['~/views/frame/frame.vue'], resolve),
    redirect: '/console/mocha',
    children: [{
        path: '/console/mocha',
        meta: {
            title: '总监控',
            keepAlive: false
        },
        menu: {
            icon: 'fa fa-home',
            pos: 0
        },
        component: resolve => require(['~/views/oss/mocha/dashboard.vue'], resolve),
    },
    {
        path: '/console/m/mocha',
        meta: {
            title: '总监控',
            keepAlive: false
        },
        menu: {
            icon: 'fa fa-home',
            pos: 0
        },
        component: resolve => require(['~/views/oss/mocha/dashboard_m.vue'], resolve),
    },
    // else if(to.path === 'console/monitor'){
    //     console.log(222);
    //     window.open("https://console.mozigu.cn/#/monitor/pvuvQuery", '_blank');
    // }
    {
        path: '/console/monitor',
        name: 'monitor',
        component: resolve => require(['~/views/oss/mocha/dashboard_m.vue'], resolve),
        beforeEnter: (to, from, next) => {
            window.open("https://console.mozigu.cn/#/monitor/pvuvQuery", '_blank');
        },
        meta: {
            title: '监控数据',
            keepAlive: false,
        },
        menu: {
            icon: 'fa fa-line-chart',
            pos: 2
        }
    },
    // {
    //   path: '/console/domain',
    //   name: 'domain',
    //   meta: {
    //     title: '域名列表',
    //     keepAlive: false
    //   },
    //   menu: {
    //     icon:'fa fa-at',
    //     pos: 1
    //   },
    //   component: resolve => require(['~/views/oss/news/domain.vue'], resolve)
    // },

    {
        path: '/console/pay_bill',
        name: 'console_pay_bill',
        meta: {
            title: '微信支付',
            keepAlive: false
        },
        component: resolve => require(['~/views/oss/news/pay_bill.vue'], resolve),
        menu: {
            icon: 'fa fa-cny',
            pos: 2
        }
    },
    {

        path: '/console/commit_log',
        name: 'log',
        meta: {
            title: '操作日志',
            keepAlive: false
        },
        menu: {
            icon: 'fa fa-pencil-square-o',
            pos: 4
        },
        component: resolve => require(['~/views/oss/commit_log.vue'], resolve),
    },
    {

        path: '/console/user_admin',
        name: 'user_admin',
        meta: {
            title: '用户管理',
            keepAlive: false
        },
        menu: {
            icon: 'fa fa-user-circle',
            pos: 7
        },
        component: resolve => require(['~/views/user_admin/user_manager.vue'], resolve),
    },
    {
        path: '/console/fans_history',
        meta: {
            title: '单日涨粉',
            keepAlive: false
        },

        // menu: {
        //     icon: 'fa fa-table',
        //     pos: 8
        // },
        component: resolve => require(['~/views/fans_history/fans_history.vue'], resolve),
    },
    {
        path: '/console/make_img_counter',
        meta: {
            title: '作图次数',
            keepAlive: false
        },

        // menu: {
        //     icon: 'fa fa-table',
        //     pos: 9
        // },
        component: resolve => require(['~/views/fans_history/make_img_counter.vue'], resolve),
    },

    ]
}