"use strict";
import axios from 'axios';
import request from './request'

export default {
    getAppDatas(platform, filter) {
      return request.get('data_admin/new_data', {platform: platform, filter:JSON.stringify(filter)});
    },
    getAppDatas_2(query) {
      /*
        {
          platform:
          filter:
          sort_key:
          table_range:
        }
      */
      return request.get('data_admin/new_data_2', query);
    },
    getDataFlowList(data) {
        let striped_data = data.map(x=>{
            return {params: x.params}
        });
        return request.get('data_admin/data_flow_2', {queryList: JSON.stringify(striped_data)});
    }
}