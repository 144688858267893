"use strict";
import $user from './user'

let all_user_tags = null;
let module_name = 'mp_apps';

export default {
    setAppsTag(apps, tag) {
        this.getAllTags().then(
            data => {
                data[tag] = apps;

                $user.setData(module_name, 'app_tags', data);
                all_user_tags = null;
            }
       );  
    },
    getTagsList() {
        return new Promise( (resolve, reject) => {
            this.getAllTags().then(
                data => {
                    let tags = [];
                    for (let k in all_user_tags) {
                        tags.push(k);
                    }

                    tags = tags.sort();
                    resolve(tags);
                },
                err => {
                    reject(err);
                }
            );  // getAllTags
        }); // Promise
    },
    getTagApps(tag) {
        return new Promise( (resolve, reject) => {
            this.getAllTags().then(
                data => {
                    resolve(tag in all_user_tags?all_user_tags[tag]:[]);                    
                },
                err => {
                    reject(err);
                }
            );  // getAllTags
        }); // Promise
    },
    getAllTags() {
        return new Promise( (resolve, reject) => {
            if (all_user_tags != null) {
                resolve(all_user_tags);
            } else {
                $user.getData(module_name, 'app_tags', {}).then(
                    data => {
                       all_user_tags = data;
                       resolve(all_user_tags);
                    },
                    err => {
                        reject(err);
                    }
                );
            }
        }); // Promise        
    },
    deleteTag(tag) {
        return new Promise( (resolve, reject) => {
            this.getAllTags().then(
                data => {
                    delete data[tag];
                    $user.setData(module_name, 'app_tags', data);
                    all_user_tags = null;
                    resolve();
                },
                err => {
                    reject();
                }
           );    
        });
    }
}