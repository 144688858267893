'use strict';
import request from './request';

export default {
    get_studio_default_materials(studio) {
        return request.get(
            'news/default_material',
            {
                studio: studio
            }
        );
    },
    post_studio_default_materials(studio, material_ids) {
        return request.post(
            'news/default_material',
            {
                studio: studio,
                material_ids: material_ids
            }
        );
    },
    del_studio_default_materials(studio, material_id) {
        return request.delete(
            'news/default_material',
            {
                studio: studio,
                material_id: material_id
            }
        );
    }
};
