'use strict';
import axios from 'axios';
import request from './request';

/**
 * 正面管教查询接口
 */

export default {
    addTag(data) {
        return new Promise((resolve, reject) => {
            request.post('gw/huozhong.positive_discipline/admin/adtag', data).then(
                (data) => {
                    resolve(data);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    },
    modifyTag(id, data) {
        return new Promise((resolve, reject) => {
            request.put(`gw/huozhong.positive_discipline/admin/adtag/${id}`, data).then(
                (data) => {
                    resolve(data);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    },
    getTags(data) {
        return new Promise((resolve, reject) => {
            request.get('gw/huozhong.positive_discipline/admin/adtags', data).then(
                (data) => {
                    resolve(data);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    },
    getChannelList() {
        return new Promise((resolve, reject) => {
            request.get('gw/huozhong.positive_discipline/admin/channels').then(
                (data) => {
                    resolve(data);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    },
    getPriceList() {
        return new Promise((resolve, reject) => {
            request.get('gw/huozhong.positive_discipline/admin/prices').then(
                (data) => {
                    resolve(data);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    },
    find(filter) {
        return new Promise((resolve, reject) => {
            request.get('gw/huozhong.positive_discipline/admin/orders', filter).then(
                (res) => {
                    resolve(res.data);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    },
    update(order_id, note) {
        return new Promise((resolve, reject) => {
            request
                .put('gw/huozhong.positive_discipline/admin/order/note', { order_id, note })
                .then(
                    (res) => {
                        resolve(res.data);
                    },
                    (err) => {
                        reject(err);
                    }
                );
        });
    },
    find_positive_discipline_user(filter) {
        return new Promise((resolve, reject) => {
            request.get('gw/huozhong.positive_discipline/admin/users', filter).then(
                (res) => {
                    resolve(res.data);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    },
    delete_positive_discipline_user(filter) {
        return new Promise((resolve, reject) => {
            request.delete(`gw/huozhong.positive_discipline/admin/user/${filter.oid}`).then(
                (res) => {
                    resolve(res.data);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }
};
