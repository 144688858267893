"use strict";
import axios from 'axios';
import request from './request'

export default {
	get_lingji(formdata){
        return new Promise( (resolve, reject) => {
            console.log('formdata',formdata);
            request.post(`lingji`, formdata).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    cookie_valid(cookie){
    	return new Promise( (resolve, reject) => {
            console.log('formdata',cookie);
            request.get(`lingji`,{'cookie':cookie}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    get_today_data(cookie){
    	console.log('cookie---data',cookie)
    	return new Promise( (resolve, reject) => {
            console.log('formdata',cookie);
            request.get(`lingji_data`,{'cookie':cookie}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    get_order_data(filters){
        return new Promise( (resolve, reject) => {
            request.get(`lingji/order`, {'filters':filters}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    get_product_name(filters){
        return new Promise( (resolve, reject) => {
            request.get(`lingji/product`, {}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    get_all_adtag(filters){
        return new Promise( (resolve, reject) => {
            request.get(`lingji/adtag`, {}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    get_all_product(filters){
        return new Promise( (resolve, reject) => {
            request.get(`lingji/product`,{'filters':filters}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

}