"use strict";
import axios from 'axios';
import request from './request'

export default {
    getMenu(filter) {
        return new Promise( (resolve, reject) => {
            request.get('mpdata_menu', {
                'filter': filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    setMenu(data) {
        return new Promise( (resolve, reject) => {
            request.post('mpdata_menu', data, 10000).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    delMenu(filter) {
        return new Promise( (resolve, reject) => {
            request.delete('mpdata_menu', {
                'filter': filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    getCondMenu(filter) {
        return request.get('mpdata_cond_menu', {'filter': filter})
    },

    setCondMenu(data) {
        return request.post('mpdata_cond_menu', data)
    },

    getCode(mpid) {
        return new Promise( (resolve, reject) => {
            console.log(mpid)
            request.get('mpdata_code', {
                'mpid': mpid
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    getCodeHistory(filters) {
        return new Promise( (resolve, reject) => {
            request.get('mpdata_code/history/list', filters).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    getCodeHistoryCode(filters) {
        return new Promise( (resolve, reject) => {
            request.get('mpdata_code/history/code', filters).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    getCodeHistoryCodeDiff(filters) {
        return new Promise( (resolve, reject) => {
            request.get('mpdata_code/history/codediff', filters).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    setCode(data) {
        return new Promise( (resolve, reject) => {
            request.post('mpdata_code', data).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    geth5url(mpid) {
        return new Promise( (resolve, reject) => {
            request.get('mpdata_h5', {mpid: mpid}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    setMuti(data) {
        return new Promise( (resolve, reject) => {
            request.put('mpdata_menu_muti', data).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
}