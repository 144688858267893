"use strict";
import axios from 'axios';
import request from '../request'

export default {
    
    getMpStrategyAndFans(mps){
        return new Promise( (resolve, reject) => {
            request.get('product/mp/strategy_fans', {
                'mps': {'mps':mps}
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    getValidInfoForMps(filter){
        return new Promise( (resolve, reject) => {
            request.get('product/mp/config_check', {
                'filter': filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    
    setMpActive(filter){
        return new Promise( (resolve, reject) => {
            request.put('product/mp/active', {
                'filter': filter,
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    
    getMpsOwnership(filter){
        return new Promise( (resolve, reject) => {
            request.get('product/mp/ownership', {
                'filter': filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    // 获取产品公号的涨粉统计数据
    getMpsFansStatistics(filter){
        return new Promise( (resolve, reject) => {
            request.get('product/mp/fans_statistics', {
                'filter': filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    // 获取上一次添加自增长公众号，是否处于总涨粉超过100的状态
    getLastTargetMpAddState(filter){
        return new Promise( (resolve, reject) => {
            request.get('product/mp/target_mp_last_add_state', {
                'filter': filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    // 以上是与产品的公众号直接相关的信息查询
}