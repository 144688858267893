"use strict";
import axios from 'axios';
import request from '../request'

export default {

    // 获取随机二维码分组
    get_random_qrcode_group(name_en){
        return new Promise( (resolve, reject) => {
            request.get('product/random_qrcode/group?name_en='+name_en, {}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    // 创建随机二维码分组
    add_random_qrcode_group(name_en){
        return new Promise( (resolve, reject) => {
            request.post('product/random_qrcode/group?name_en='+name_en, {}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    // 为随机二维码分组创建新uri
    add_random_qrcode_uri(name_en,data){
        return new Promise( (resolve, reject) => {
            request.post('product/random_qrcode/uri?name_en='+name_en,data).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
}