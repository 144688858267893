"use strict";
import axios from 'axios';
import request from './request'

export default {
	    getFansHistory(timestamp) {
        return new Promise( (resolve, reject) => {
        	console.log('timestamp',timestamp);
            request.get('api_fans_history',{'timestamp':timestamp}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    }
}