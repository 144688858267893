'use strict';
import axios from 'axios';
import request from './request';

export default {
    getMaterialList(filter) {
        return new Promise((resolve, reject) => {
            request
                .get('news/material', {
                    filter: filter
                })
                .then(
                    (data) => {
                        resolve(data);
                    },
                    (err) => {
                        reject(err);
                    }
                );
        });
    },
    getMaterialHis(id) {
        return new Promise((resolve, reject) => {
            request
                .get('news/article_history', {
                    id: id
                })
                .then(
                    (data) => {
                        resolve(data);
                    },
                    (err) => {
                        reject(err);
                    }
                );
        });
    },
    setMaterialStatus(material_id, status) {
        return new Promise((resolve, reject) => {
            let valid_status = [0, 1, -1];
            if (valid_status.indexOf(status) > -1) {
                request
                    .put('news/material/' + material_id, {
                        status: status
                    })
                    .then(
                        (data) => {
                            resolve(data);
                        },
                        (err) => {
                            reject(err);
                        }
                    );
            } else {
                let msg = 'invalid material status:' + status;
                console.error(msg);
                reject(msg);
            }
        });
    },
    getScheduleList(filter, lite) {
        // lite = lite || false;
        // return new Promise( (resolve, reject) => {
        //     request.get('news/schedule', {
        //         'filter': filter,
        //         'lite': lite?1:0
        //     }).then( data=>{
        //         resolve(data);
        //     }, err => {
        //         reject(err)
        //     });
        // });
        lite = lite || false;
        return new Promise((resolve, reject) => {
            request.post(`news/find_schedule?lite=${lite ? 1 : 0}`, filter).then(
                (data) => {
                    resolve(data);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    },
    getStudios() {
        return new Promise((resolve, reject) => {
            request.get('news/studio', { permission_mode: true }).then(
                (data) => {
                    resolve(data);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    },
    getStudioList() {
        return new Promise((resolve, reject) => {
            request.get('news/company').then(
                (data) => {
                    resolve(data[0]['selection']['studio']);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    },
    getPerStudioList() {
        return new Promise((resolve, reject) => {
            request.get('news/permission_studio').then(
                (data) => {
                    resolve(data);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    },
    getCompanyList() {
        return new Promise((resolve, reject) => {
            request.get('news/company').then(
                (data) => {
                    resolve(data);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    },
    addCompany(company) {
        /*
            添加公司
        */
        return request.post('news/company', { company: company });
    },
    getScheduleStatus() {
        return new Promise((resolve, reject) => {
            resolve([
                {
                    key: 100,
                    label: '排期中'
                },
                {
                    key: 0,
                    label: '待发布'
                },
                {
                    key: 1,
                    label: '队列中'
                },
                {
                    key: 2,
                    label: '发布中'
                },
                {
                    key: 3,
                    label: '已发布'
                },
                {
                    key: 4,
                    label: '确保发布'
                },
                {
                    key: 7,
                    label: '重新发布'
                },
                {
                    key: -1,
                    label: '发失败'
                },
                {
                    key: -2,
                    label: '已过期'
                },
                {
                    key: -3,
                    label: '排期失败'
                },
                {
                    key: -4,
                    label: '修改失败'
                }
            ]);
        });
    },
    replaceScheduleMaterial(schedule_id, index, material_id, detail) {
        return new Promise((resolve, reject) => {
            request
                .put('news/schedule/material/' + schedule_id, {
                    index: index,
                    material_id: material_id,
                    detail: detail
                })
                .then(
                    (data) => {
                        resolve(data);
                    },
                    (err) => {
                        reject(err);
                    }
                );
        });
    },
    checkAttrs(filter) {
        return new Promise((resolve, reject) => {
            request
                .get('news/mp_app/exist', {
                    filter: filter
                })
                .then(
                    (data) => {
                        resolve(data);
                    },
                    (err) => {
                        reject(err);
                    }
                );
        });
    },
    checkAppSecret(mpid, appid, appsecret) {
        return new Promise((resolve, reject) => {
            console.log('second', mpid, appid, appsecret);
            request
                .get('news/checkapp/' + mpid, {
                    appid: appid,
                    appsecret: appsecret
                })
                .then(
                    (data) => {
                        resolve(data);
                    },
                    (err) => {
                        reject(err);
                    }
                );
        });
    },
    getMpApps(filter) {
        return new Promise((resolve, reject) => {
            var filters = {
                type: filter['filter_type'],
                studio: filter['filter_studio'],
                company: filter['filter_company'],
                title: filter['filter_title'],
                title2: filter['filter_title2'],
                date_valid: filter['date_valid'],
                date_data: filter['filter_date'],
                filter_fans: filter['filter_fans'],
                mps: filter['mps'],
                admin: filter['admin'],
                mpsfans: filter['filter_fans'],
                moved: filter['moved'],
                unauthorized: filter['unauthorized'],
                tag: filter.tag,
                ignore_unauthorized: !!filter.ignore_unauthorized
            };
            request
                .post('news/mp_app_v2', {
                    filter: filters
                })
                .then(
                    (data) => {
                        resolve(data);
                    },
                    (err) => {
                        reject(err);
                    }
                );
        });
    },
    getMpAppsProductInfo(filter) {
        return new Promise((resolve, reject) => {
            request.get('news/mp_app_product', {}).then(
                (data) => {
                    resolve(data);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    },
    getMpAppsV2(filter) {
        return new Promise((resolve, reject) => {
            request
                .get('news/mp_app_data', {
                    filter: {
                        studio: filter['filter_studio'],
                        title: filter['filter_title'],
                        title2: filter['filter_title2'],
                        date_valid: filter['date_valid'],
                        date_data: filter['filter_date']
                    }
                })
                .then(
                    (data) => {
                        resolve(data);
                    },
                    (err) => {
                        reject(err);
                    }
                );
        });
    },
    getMpAppsByAppid(appids) {
        return new Promise((resolve, reject) => {
            request
                .post('news/mp_apps_v2', {
                    appids: appids
                })
                .then(
                    (data) => {
                        resolve(data);
                    },
                    (err) => {
                        reject(err);
                    }
                );
        });
    },

    updateMpApps(filter, appid) {
        console.log('updata_data', filter, appid);
        return new Promise((resolve, reject) => {
            request.put('news/mp_app/' + appid, filter).then(
                (data) => {
                    resolve(data);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    },
    batchUpdateApps(filter, appids) {
        return new Promise((resolve, reject) => {
            request.put('news/mp_app_batch', { filter, appids }).then(
                (data) => {
                    resolve(data);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    },
    deleteMpApps(appid) {
        console.log('delete', appid);
        return new Promise((resolve, reject) => {
            request.get('news/delete_app/' + appid).then(
                (data) => {
                    resolve(data);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    },
    createMpApps(items, appid) {
        console.log('create_data', items);
        return new Promise((resolve, reject) => {
            request.post('news/mp_app/' + appid, items).then(
                (data) => {
                    console.log('first_data', data);
                    resolve(data);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    },

    createSchedule(
        appid,
        pub_datetime,
        items,
        sid = null,
        replace_mode = false,
        send_ignore_reprint = true,
        is_to_all = true
    ) {
        return this.createScheduleV2({
            sid: sid,
            appid: appid,
            pub_datetime: pub_datetime,
            items: items,
            replace_mode: replace_mode,
            send_ignore_reprint: send_ignore_reprint,
            is_to_all: is_to_all
        });
    },
    createScheduleV2(opts) {
        return request.post('news/schedule', opts);
    },
    // 删除推文排期
    deleteSchedule(schedule_id) {
        return new Promise((resolve, reject) => {
            request.delete('news/schedule/' + schedule_id).then(
                (data) => {
                    resolve(data);
                },
                (err) => {
                    reject(err);
                }
            ); // then
        }); // promise
    },

    // items: [{id: '', 'pub_datetime'}]
    setSchedulesPubTime(items) {
        return new Promise((resolve, reject) => {
            request.put('news/schedules/pub_datetime', items).then(
                (data) => {
                    resolve(data);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    },

    getScheduleInfo(schedule_id) {
        return new Promise((resolve, reject) => {
            request
                .get('news/schedule', {
                    filter: {
                        id: schedule_id
                    }
                })
                .then(
                    (data) => {
                        if (data.length == 1) {
                            resolve(data[0]);
                        } else {
                            resolve(null);
                        }
                    },
                    (err) => {
                        reject(err);
                    }
                );
        });
    },
    batchCreateNews(mpid, title, from_url, content_source_url, logo_url, count) {
        return new Promise((resolve, reject) => {
            request
                .post('materials', {
                    mpid: mpid,
                    title: title,
                    from_url: from_url,
                    content_source_url: content_source_url,
                    logo_url: logo_url,
                    count: count
                })
                .then(
                    (data) => {
                        resolve(data);
                    },
                    (err) => {
                        reject(err);
                    }
                );
        });
    },
    // 手动同步素材
    fetchNews(mpname, days) {
        return new Promise((resolve, reject) => {
            request
                .put('materials', {
                    mpname: mpname,
                    days: days
                })
                .then(
                    (data) => {
                        resolve(data);
                    },
                    (err) => {
                        reject(err);
                    }
                );
        });
    },

    getAppsScheduleStatus(apps, date) {
        return new Promise((resolve, reject) => {
            var count = 0;
            var ret_count = 0;
            var results = {};

            if (apps.length > 0) {
                for (let appid in apps) {
                    results[appid] = false;
                }

                while (apps.length > 0) {
                    let req_apps = apps.splice(0, 100);
                    count++;
                    request
                        .get('news/schedule/plan_status', {
                            appids: req_apps.join(','),
                            date: date
                        })
                        .then(
                            (data) => {
                                for (let appid in data) {
                                    results[appid] = data[appid];
                                }
                                ret_count++;
                                if (ret_count == count) {
                                    resolve(results);
                                }
                            },
                            (err) => {
                                //reject(err)
                                ret_count++;
                                if (ret_count == count) {
                                    resolve(data);
                                }
                            }
                        );
                }
            } else {
                resolve(results);
            }
        });
    },
    // 删除已发布文章
    deletePublishedScheduleMaterial(schedule_id, material_id) {
        return new Promise((resolve, reject) => {
            request.delete(`news/schedule/material/${schedule_id}/${material_id}`, {}).then(
                (data) => {
                    resolve(data);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    },
    deleteMultiPublishedScheduleMaterial(schedule_id_news) {
        console.log(schedule_id_news);
        return new Promise((resolve, reject) => {
            request
                .post(`news/schedule/news/multi`, {
                    schedule_id_news: schedule_id_news
                })
                .then(
                    (data) => {
                        resolve(data);
                    },
                    (err) => {
                        reject(err);
                    }
                );
        });
    },
    translate_page(url, title) {
        return new Promise((resolve, reject) => {
            request
                .get(`api_translate`, {
                    params: {
                        url: url,
                        title: title
                    }
                })
                .then(
                    (data) => {
                        resolve(data);
                    },
                    (err) => {
                        reject(err);
                    }
                );
        });
    },
    // mp_record(filter_list){
    //     console.log(filter_list)
    //     filter_list = {name: '1111'}
    //     return new Promise( (resolve, reject) => {
    //         request.get(`mp_classify`, {
    //             filter_list: filter_list
    //         }).then( data=>{
    //             resolve(data);
    //         }, err => {
    //             reject(err);
    //         });
    //     });
    // },
    mp_record(filter_list) {
        console.log(filter_list);
        return new Promise((resolve, reject) => {
            request
                .post(`mp_classify`, {
                    filter_list: filter_list
                })
                .then(
                    (data) => {
                        resolve(data);
                    },
                    (err) => {
                        reject(err);
                    }
                );
        });
    },

    getCompanyListV2() {
        return new Promise((resolve, reject) => {
            request.get('news/company_v2').then(
                (data) => {
                    resolve(data);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    },
    addCompanyV2(company_info) {
        /*
            添加公司
        */
        return request.post('news/company_v2', company_info);
    },
    updateCompanyV2(company_id, update_company_info) {
        /*
            更新公司信息
        */
        return request.put('news/company_v2/' + company_id, update_company_info);
    },
    deleteCompanyV2(company_id) {
        /*
            更新公司信息
        */
        return request.delete('news/company_v2/' + company_id);
    }
};
