"use strict";
import axios from 'axios';
import request from './request'

export default {
    getMarketList(filter) {
        return new Promise( (resolve, reject) => {
            //console.log(filter)
            request.get('moapp/controls', {
                filter: filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    createMarket(data) {
        console.log(data)
        return new Promise( (resolve, reject) => {
            request.post('moapp/controls', data).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    deleteMarket(id) {
        console.log(id)
        return new Promise( (resolve, reject) => {
            request.delete('moapp/controls', {id: id}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    getSpaceList(filter) {
        return new Promise( (resolve, reject) => {
            //console.log(filter)
            request.get('moapp/controls/space').then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    getClassifyList(filter) {
        return new Promise( (resolve, reject) => {
            //console.log(filter)
            request.get('moapp/controls/classify').then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    getCodes(id) {
        return new Promise( (resolve, reject) => {
            //console.log(filter)
            request.get('moapp/controls/code', {'id': id}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    setCodes(data) {
        return new Promise( (resolve, reject) => {
            //console.log(filter)
            request.put('moapp/controls/code', data).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    buildSample(widget_id, test_code) {
        return new Promise( (resolve, reject) => {
            request.post('moapp/widget/sample/'+widget_id, test_code).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    }
}