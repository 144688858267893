"use strict";
import axios from 'axios';
import request from './request'

export default {
    find(filters) {
        return new Promise( (resolve, reject) => {
            request.get('mp_admin',{'filters':filters}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    add(form){
        return new Promise( (resolve, reject) => {
            request.post('mp_admin',form).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    update(rid, params) {
        console.log('uipdate')
        console.log(rid)
        console.log(params)

        return new Promise( (resolve, reject) => {
            request.put('mp_admin',{'rid':rid, 'params': params}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    delete(rid){
        return new Promise( (resolve, reject) => {
            request.delete('mp_admin',{'rid':rid}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    batchModifySignature(apps, defaultSignature) {
        return request.put('mp/signature', {
            apps: apps,
            default_signature: defaultSignature
        })
    },
    batchModifyLogo(apps, url) {
        return request.put('mp/logo', {
            apps: apps,
            url: url
        })
    }
}