"use strict";
import request from './request'

export default {

    get_history_version_info(product_name){
        return new Promise( (resolve, reject) => {
            request.get('product_test/history', {
                'product_name': product_name
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    get_entrance_visiable_right(){
        return new Promise( (resolve, reject) => {
            request.get('product_test/entrance/visiable', {}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    get_bgs(product_name){
        return new Promise( (resolve, reject) => {
            request.get('product_test/bgs', {
                'product_name': product_name
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    get_bgs_by_tid(tid){
        return new Promise( (resolve, reject) => {
            request.get('product_test/bgs', {
                'tid': tid
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    get_setting(product_name){
        return new Promise( (resolve, reject) => {
            request.get('product_test/setting', {
                'product_name': product_name
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    create_setting(data) {
        return new Promise( (resolve, reject) => {
            request.post('product_test/setting', data).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    update_setting(data) {
        return new Promise( (resolve, reject) => {
            request.put('product_test/setting', data).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    update_status(data) {
        return new Promise( (resolve, reject) => {
            request.put('product_test/set_status', data).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    start_new(data) {
        return new Promise( (resolve, reject) => {
            request.post('product_test/set_status', data).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    enable_product(data) {
        return new Promise( (resolve, reject) => {
            request.post('product_test/control/enable', data).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    disable_product(data) {
        return new Promise( (resolve, reject) => {
            request.post('product_test/control/disable', data).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
}
