"use strict";
import axios from 'axios';
import request from './request'

export default {
    getResumeData(filter, projection){
            return new Promise( (resolve, reject) => {
                request.get('resume_data', {
                    'filter': JSON.stringify(filter),
                    'projection': JSON.stringify(projection)
                }
                ).then( data=>{
                    resolve(data);
                }, err => {
                    reject(err)
                });
            });
        },
    updateResumeState(id, state){
        var post_data = {
            '_id':id,
            'state':state
        }

        // JSON.stringify(post_data)
        return new Promise( (resolve, reject) => {
            request.put('resume_data/state', post_data).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    getPosition(){
        return new Promise( (resolve, reject) => {
            request.get('resume_admin').then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    setCookie(source, cookie){
        var post_data = {
            'source':source,
            'cookie':cookie
        }

        // JSON.stringify(post_data)
        return new Promise( (resolve, reject) => {
            request.put('cookie' , post_data).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

}
