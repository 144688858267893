"use strict";
import axios from 'axios';
import request from './request'

export default {
  add_cron(params){
    return request.post('cron_admin/', params)
  },
  fetch_cron_list(params) {
    return request.get('cron_admin/', params)
  },
  modify_cron(cron_id, params) {
    return request.put('cron/' + cron_id, params) // nginx
  },
  del_cron(cron_id, params) {
    return request.delete('cron_admin/' + cron_id, params)
  },
  get_cron_log(cron_id) {
    return request.get(`cron/${cron_id}/job/`) // nginx
  },
  get_func_info(function_id) {
    return request.get('function/' + function_id) // nginx
  },
  add_func(params) {
    return request.post('function_admin/', params)
  },
  modify_func(function_id, params) {
    return request.put('function/' + function_id, params) // nginx
  },
  del_func(function_id) {
    return request.delete('function_admin/' + function_id)
  },
  debug_func(function_id) {
    return request.post(`function/${function_id}/debug/`) // nginx
  }
}