export default {
    path: '/tools',
    redirect: '/tools/page_create',
      component: resolve => require(['~/views/tools/page_create.vue'], resolve),
    // title: '工具',
    // component: resolve => require(['~/views/frame/frame.vue'], resolve),
    // meta: {
    //   title: '工具',
    //   keepAlive: false,
    // },
  children: [
    {
      path: '/tools/page_create',
      component: resolve => require(['~/views/tools/page_create.vue'], resolve),
      // meta: {
      //   title: '页面生成',
      //   keepAlive: false
      // },
      // menu: {
      //   icon: 'fa fa-columns',
      //   pos: 0
      // },
    },
    {
      path: '/tools/audio',
      component: resolve => require(['~/views/tools/audio.vue'], resolve),
      // meta: {
      //   title: '音频处理',
      //   keepAlive: false
      // },
      // menu: {
      //   icon: 'fa fa-microphone',
      //   pos: 0
      // },
    },   
  ]
}
