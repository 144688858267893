"use strict";
import axios from 'axios';
import request from './request'

export default {
    get_group(filter){
        return new Promise( (resolve, reject) => {
            request.get('mp_uri_group', {
                'filter': filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    del_group(group_id) {
        return new Promise( (resolve, reject) => {
            request.delete('mp_uri_group', {
                'group_id': group_id
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    add_group(group_data) {
        return new Promise( (resolve, reject) => {
            request.post('mp_uri_group', group_data).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    createUris(data) {
        return new Promise( (resolve, reject) => {
            request.post('mp_uris', data).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    del_uri(uri_id) {
        console.log(uri_id)
        return new Promise( (resolve, reject) => {
            request.delete('mp_uris', {
                'uri_id': uri_id
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    }
    // updateDomains(data) {
    //     return new Promise( (resolve, reject) => {
    //         request.put('domain_mgr', data).then( data=>{
    //             resolve(data);
    //         }, err => {
    //             reject(err)
    //         });
    //     });
    // },
    // deleteDomains(domain) {
    //     return new Promise( (resolve, reject) => {
    //         request.delete('domain_mgr', {domain: domain}).then( data=>{
    //             resolve(data);
    //         }, err => {
    //             reject(err)
    //         });
    //     });
    // },
    // getWatchDomains() {
    //     return new Promise( (resolve, reject) => {
    //         request.get('domain_watch').then( data=>{
    //             resolve(data);
    //         }, err => {
    //             reject(err)
    //         });
    //     });
    // }
}