"use strict";
import axios from 'axios';
import request from '../request'

export default {

    checkProductNameValidity(name){
        return new Promise( (resolve, reject) => {
            request.get('product/query/duplicate', {
                'name': name
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    getProductInfo(filter){
        return new Promise( (resolve, reject) => {
            request.get('product/query/index/multi', {
                'filter': filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    getProductLazyData(filter){
        return new Promise( (resolve, reject) => {
            request.get('product/query/index/lazy', {
                'filter': filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    // 以上为主页批量查询接口
}