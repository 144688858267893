"use strict";
import axios from 'axios';
import request from './request'

export default {
    getComponents(filter) {
        return new Promise( (resolve, reject) => {
            //console.log(filter)
            request.get('moapp/components', {
                'filter': filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    createComponent(name, chinese_name, project_name) {
        return new Promise( (resolve, reject) => {
            request.post('moapp/components', {
                'name': name,
                'chinese_name': chinese_name,
                'project_name': project_name
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    deleteComponent(component_id){
        return new Promise((resolve, reject) => {
            request.delete('moapp/components', {
                'component_id': component_id
            }).then( data=> {
                resolve(data);
            }, err=>{
                reject(err)
            })
        })
    },
    updateComponent(component){
        return new Promise((resolve, reject) => {
            request.put('moapp/components', {
                'component': component
            }).then( data=> {
                resolve(data);
            }, err=>{
                reject(err)
            })
        })
    },

    getComponentCode(name) {
        return new Promise( (resolve, reject) => {
            //console.log(filter)
            request.get('moapp/components/code', {
                'name': name
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    setComponentCode(name, component_code, test_code) {
        return new Promise( (resolve, reject) => {
            //console.log(filter)
            request.post('moapp/components/code', {
                'name': name,
                'component_code': component_code,
                'test_code': test_code
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },




}