import Vue from 'vue';
import Router from 'vue-router';
import miniprogram from './miniprogram';
import console_menu from './console';
import oss from './oss';
import mps from './mps';
import astrology from './astrology';
import translate from './translate';
import tools from './tools';
Vue.use(Router);

let routes = [
    {
        path: '/',
        redirect: '/about'
    },
    // 登录页面
    {
        path: '/login',
        name: 'login',
        meta: {
            title: '后台登录',
            keepAlive: false
        },
        components: {
            blank: (resolve) => require(['~/views/login/login.vue'], resolve)
        }
    },
    // 关于页面
    {
        path: '/about',
        name: 'about',
        meta: {
            title: '关于我们',
            keepAlive: false
        },
        components: {
            blank: (resolve) => require(['~/views/about/index.vue'], resolve)
        }
    },
    {
        path: '/debug/test',
        name: 'debug',
        meta: {
            title: 'DEBUG页面',
            keepAlive: false
        },
        components: {
            blank: (resolve) => require(['~/views/about/debug.vue'], resolve)
        }
    },
    {
        path: '/dataadmin',
        name: 'dataadmin',
        component: (resolve) => require(['~/views/dataadmin/dataadmin.vue'], resolve),
        meta: {
            title: '数据管理',
            keepAlive: false
        }
    },
    {
        path: '/apps',
        name: 'apps',
        meta: {
            title: '应用管理',
            keepAlive: false
        },
        component: (resolve) => require(['~/views/apps/apps.vue'], resolve)
    },
    {
        path: '/apps/create',
        name: 'create',
        meta: {
            title: '创建应用',
            keepAlive: false
        },
        component: (resolve) => require(['~/views/apps/create.vue'], resolve)
    },
    {
        path: '/user/edit',
        name: 'edit',
        meta: {
            title: '修改个人信息',
            keepAlive: false
        },
        component: (resolve) => require(['~/views/user/edit.vue'], resolve)
    },
    {
        path: '/tutorial',
        component: (resolve) => require(['~/views/miniprogram/tutorial/tutorial.vue'], resolve),
        meta: {
            title: 'MoApp教程',
            keepAlive: false
        }
    },
    {
        path: '/tutorial_manager',
        component: (resolve) =>
            require(['~/views/miniprogram/tutorial/tutorial_manager.vue'], resolve),
        meta: {
            title: 'MoApp教程管理',
            keepAlive: false
        }
    },
    {
        path: '/batch_create_material',
        component: (resolve) => require(['~/views/oss/news/batch_create_materials.vue'], resolve),
        meta: {
            title: '批量创建素材',
            keepAlive: false
        }
    },
    {
        path: '/tools/code_editor',
        component: (resolve) => require(['~/views/tools/code_editor_demo.vue'], resolve),
        meta: {
            title: '代码编辑器demo',
            keepAlive: false
        }
    },
    {
        path: '/tools/mobile_preview_wrapper',
        component: (resolve) =>
            require(['~/views/components/mobile_preview/iframe_wrapper.vue'], resolve),
        meta: {
            title: '',
            keepAlive: false
        }
    },
    {
        path: '/tools/audio',
        component: (resolve) => require(['~/views/tools/audio.vue'], resolve)
    },
    {
        path: '/tools/resume',
        component: (resolve) => require(['~/views/tools/resume.vue'], resolve)
    },
    {
        path: '/tools/video_barrage',
        component: (resolve) => require(['~/views/tools/video_barrage.vue'], resolve),
        meta: {
            title: '视频字幕制作',
            keepAlive: false
        }
    },
    {
        path: '/tools/m/mox_order',
        component: (resolve) => require(['~/views/tools/m_positive_discipline'], resolve),
        meta: {
            title: '正面管教订单查询',
            keepAlive: false
        },
        beforeEnter: (to, from, next) => {
            window.location.href = 'https://zmgj.mozigu.cn/';
        }
    },
    {
        path: '/tools/mox_order',
        component: (resolve) => require(['~/views/tools/positive_discipline/order.vue'], resolve),
        meta: {
            title: '正面管教订单查询',
            keepAlive: false
        },
        beforeEnter: (to, from, next) => {
            window.location.href = 'https://zmgj.mozigu.cn/';
        }
    },
    {
        path: '/tools/positive_discipline_user',
        component: (resolve) => require(['~/views/tools/positive_discipline/user.vue'], resolve),
        meta: {
            title: '正面管教小程序用户',
            keepAlive: false
        }
    },
    {
        path: '/tools/my_video',
        component: (resolve) => require(['~/views/tools/my_video.vue'], resolve),
        meta: {
            title: '我的视频',
            keepAlive: false
        }
    },
    {
        path: '/mpdata/forbid',
        component: (resolve) => require(['~/views/oss/news/mpdata_forbid_m.vue'], resolve)
    },
    {
        path: '/vote/phrase',
        meta: {
            title: '文案评价',
            keepAlive: false
        },
        component: (resolve) => require(['~/views/oss/phrasebook/phrase_vote_index.vue'], resolve)
    },
    {
        path: '/phrasebook/index',
        meta: {
            title: '文案库',
            keepAlive: false
        },
        component: (resolve) => require(['~/views/oss/phrasebook/index.vue'], resolve)
    },
    {
        path: '/phrasebook/rank/index',
        meta: {
            title: '文案排名',
            keepAlive: false
        },
        component: (resolve) =>
            require(['~/views/oss/phrasebook/phrase_vote_rank_index.vue'], resolve)
    },
    
    {
        path: '/tools/constellation_article_data',
        component: (resolve) =>
            require(['~/views/tools/constellation_article_data/index.vue'], resolve),
        meta: {
            title: '星座号数据统计',
            keepAlive: false
        }
    }
];

routes.push(miniprogram);
routes.push(console_menu);
routes.push(oss);
routes.push(mps);
routes.push(translate);
routes.push(tools);
routes.push(astrology);
export default new Router({
    routes: routes
});
