"use strict";
import axios from 'axios';
import request from './request'

export default {
    getMakeImgTimes(filter) {
        return new Promise( (resolve, reject) => {
            //console.log(filter)
            request.get('make_image_times', {
                'filter': filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
}
