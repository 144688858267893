/*
 * @Author: your name
 * @Date: 2021-06-24 14:14:49
 * @LastEditTime: 2021-06-25 10:07:06
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \web\src\main.js
 */
import Vue from 'vue';
import ElementUI from 'element-ui';

import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/font-awesome.min.css';
import './assets/css/animate.min.css';
import './assets/css/common.less';

import Config from './config';
import router from './router';
import Api from './api';
import user from './api/user.js';
import utils from './utils';
import Menu from '~/menu/index';
import { default as mooa } from '@mzg/mooa';
for (const k in Api) {
    Vue.prototype['$' + k] = Api[k];
}

Vue.prototype.$config = Config;
Vue.prototype.$utils = utils;

import App from './app.vue';
Vue.use(ElementUI);


window.mooa = mooa;
mooa.init();
import VueClipboard from 'vue-clipboard2';

Vue.use(VueClipboard);

// 暂时通过session_valid来缓存session校验
utils.setCookie('session_valid', null);

window.router = router;
router.beforeEach(function(to, from, next) {
    window.document.title = to.meta.title ? to.meta.title : Menu.title();

    if (to.path === '/about' || to.path === '/debug/test') {
        next();
    } else {
        // next();
        user.checkLogin({
            successed: (url) => {
                if (url) {
                    location.href = url;
                    next();
                } else {
                    Menu.update(location.href);
                    next();
                }
            },
            failed: () => {
                if (to.path !== '/login') {
                    next({ path: '/login?redirect=' + escape(location.href) });
                } else {
                    next();
                }
            }
        });
    }
});

router.beforeResolve((to, from, next) => {
    if (from.query.__id__ && !to.query.__id__) {
        to.query.__id__ = from.query.__id__;
    }
    next();
});

utils.initViewport();

new Vue({
    router,
    render: (h) => h(App)
}).$mount('#app');
