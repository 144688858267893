"use strict";
import axios from 'axios';
import request from './request'

export default {
    getArticles(filter){
        return new Promise( (resolve, reject) => {
            request.get('articleLibApi', {
                'filter': filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    addArticle(data){
        return new Promise( (resolve, reject) => {
            request.post('articleLibApi', {
                'data': data
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    updateArticle(post_data){

        // JSON.stringify(post_data)
        return new Promise( (resolve, reject) => {
            request.put('articleLibApi', {
                'post_data': post_data
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    deleteArticle(id){
        return new Promise( (resolve, reject) => {
            request.delete('articleLibApi', {
                'artice_id': id
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    // changeMoAppCodeInstance(data){
    //     return new Promise( (resolve, reject) => {
    //         request.put('turorialCodeApi', {
    //             'data': data
    //         }).then( data=>{
    //             resolve(data);
    //         }, err => {
    //             reject(err)
    //         });
    //     });
    // },
    //
    // deleteMoAppCodeInstance(data){
    //     return new Promise( (resolve, reject) => {
    //         request.delete('turorialCodeApi', {
    //             'data': data
    //         }).then( data=>{
    //             resolve(data);
    //         }, err => {
    //             reject(err)
    //         });
    //     });
    // },
}
