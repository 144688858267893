import oss from '../router/oss';
import mps from '../router/mps';
import console_menu from '../router/console';
import miniprogram from '../router/miniprogram';
import tools from '../router/tools'
import astrology from '../router/astrology'

// 可用图标地址:http://www.fontawesome.com.cn/icons-ui/  

export default  {
    subtitle: '',
    getCurrent() {
        return this.update();
    },
    title() {
      return this._get_menu_obj().title;
    },
    update() {
      this.subtitle = '';
      return this.create_menu(this._get_menu_obj());
    },
    _get_menu_obj() {
      let obj = oss;

      if (location.href.indexOf('/#/oss') > -1) {
        obj = oss;
      } else if (location.href.indexOf('/#/miniprogram') > -1) {
        obj = miniprogram;
      } else if (location.href.indexOf('/#/mps') > -1) {
        obj = mps;
      } else if (location.href.indexOf('/#/console') > -1) {
        obj = console_menu;
      } else if (location.href.indexOf('/#/tools') > -1) {
        obj = tools;
      }else if (location.href.indexOf('/#/astrology') > -1) {
        obj = astrology;
      }else {
      }

      return obj;
    },
    create_menu(obj) {
      let items = {};
      obj.children.forEach( item => {
        if (item.menu) {
          if ( ! (item.meta.title in items)) {
            items[item.meta.title] = {
              name: item.meta.title
            };
          }

          if (item.path.indexOf('/m/') > -1) {
            items[item.meta.title].path_m = item.path;
          } else {
            items[item.meta.title].path = item.path;
            items[item.meta.title].icon = item.menu.icon;
            items[item.meta.title].pos = item.menu.pos;
          }
        }
      });

      let menus = [];
      for (let name in items) {
         menus.push(items[name]);
      }

      return menus.sort((a, b) => {
        return a.pos - b.pos
      });
    }
};