"use strict";
import request from './request'

/*
    请求monitor数据的前端接口 
 */

export default {
    get_new_stat_by_app(new_stat_by_app_query) {
        /* new_stat_by_app_query: {
             app: app,
             pattern: 正则匹配筛选，可选参数   
        }*/ 
        return request.get('monitor_data/new_stat_by_app', new_stat_by_app_query);
    },
    get_his_stat_by_app(his_stat_by_app_query) {
        // his_stat_by_app_query: {
        //     app: app,
        //     pattern: 正则匹配筛选，可选参数  
        //     begin_time: 开始时间戳
        //     end_time: 可选参数
        // }
        return request.get('monitor_data/his_stat_by_app', his_stat_by_app_query);
    },
    get_his_sum_by_app(his_sum_by_app_query) {
        // his_stat_by_app_query: {
        //     app: app,
        //     pattern: 正则匹配筛选，可选参数  
        //     begin_time: 开始时间戳
        //     end_time: 可选参数
        // }
        return request.get('monitor_data/his_sum_by_app', his_sum_by_app_query);
    },
    get_data_flow(query) {
        /* query: [
          {
            app
            key
            flowtype
          }
        ]
        }*/
        return Promise.all(query.map(item => {
          let app = item.app;
          delete item['app'];
          return request.get(`monitor/data_flow/${app}`, item);
        }))
    },
    get_recent_data(query) {
      /*
        query: {
          apps:
          keys:
          minutes:
        }
      */
      return request.get('monitor_data/recent_data', query);
    },
    get_new_stat(query) {
      /*
        query: {
          apps: json格式
          keys: json格式
        }
      */
     return request.get('monitor/pvuvdata/power_data_api', query);
    },
    get_average_flow(app, query) {
      /*
        query: {
          key:
          begin_time_stamp:
          end_time_stamp:
        }
      */
      return request.get('monitor/average_flow/' + app, query);
    },
    get_volume_flow(app, query){
      return request.get('monitor/volume_flow/'+ app, query);
    },
    get_cohort(app, query) {
      /*
        query:
          init_key:
          ret_key:
          init_time:
          ret_time: 
      */
      return request.get('monitor/cohort/'+ app, query);
    },
    get_state() {
        return request.get('monitor/state');
    },
    get_mocha_stat_by_app(items){
        return new Promise( (resolve, reject) => {
            console.log('----------items',items)
            request.get('api_mps',{'mpid':items}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    get_sub_his(mpid, date) {
      return new Promise( (resolve, reject) => {
        request.get('mp_sub_his',{mpid: mpid, date: date}).then( data=>{
            resolve(data);
        }, err => {
            reject(err)
        });
    });
    }
}