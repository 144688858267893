"use strict";
import axios from 'axios';
import request from './request'

export default {
    getQrcodeUrl(url){
        return new Promise( (resolve, reject) => {
            request.get('mobile_preview/qrcode', {
                'filters': {
                    'url':url
                }
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
}