"use strict";
import axios from 'axios';
import request from './request'

export default {
    getDatabase(env, appid) {
        return request.get('database', {env:env, appid:appid});
    },
    getTableData(env, appid, table, page=1){
        const data = request.get('tables',{env:env, appid:appid, table:table, page:page});
        console.log(data)
        return data 
    },
    addCustomTableRecord(env, appid, table, fields){
        const data = request.post('custom_table/record',{env:env, appid:appid, table:table, fields:fields});
        console.log(data)
        return data 
    },
    getCustomTables(appid, env){
        const data = request.get('custom_table',{appid:appid, env:env});
        console.log(data)
        return data 
    },
    addCustomTable(appid, tableName, fields){
        const data = request.post('custom_table',{appid:appid, tableName:tableName, fields:fields});
        console.log(data)
        return data 
    },
    deleteCustomTableRecord(env, appid, tableName, id){
        const data = request.delete('custom_table/record',{env:env, appid:appid, tableName:tableName, id:id});
        console.log(data)
        return data 
    },
    getCustomTableRecord(env, appid, tableName, id){
        const data = request.get('custom_table/record',{env:env, appid:appid, tableName:tableName, id:id});
        console.log(data)
        return data 
    },
    updateCustomTableRecord(env, appid, table, record){
        const data = request.post('custom_table/record',{env:env, appid:appid, table:table, record: record});
        console.log(data)
        return data 
    },
    saveTableCode(appid, tableName, codes, sampleCodes){
        const data = request.post('custom_table/codes', {appid:appid, tableName:tableName, codes:codes, sampleCodes:sampleCodes});
        console.log(data)
        return data
    },
    getTableCode(appid, tableName){
        console.warn('tableName'+tableName)
        const data = request.get('custom_table/codes', {appid:appid, tableName:tableName});
        console.log(data)
        return data
    },
    getRealAppid(moapp_appid){
        const data = request.get('realAppid', {'moapp_appid': moapp_appid});
        console.log(data)
        return data
    },
    import_excel(appid, env, excel_file){
         return new Promise( (resolve, reject) => {
            var formData = new FormData();
            formData.append('file', excel_file);
            // formData.append('appid', appid);
            // formData.append('env', env);
            const data = request.post('database_file_import?appid='+appid +'&env='+env, formData).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
            
        })
    },
    transfer_table(source_env, source_appid,  source_table_name, dist_env, dist_appid, dist_table_name, empty_dist_table){
         return new Promise( (resolve, reject) => {
            const data = request.post('transfer_table', {source_env:source_env, 
                                                        source_appid:source_appid, 
                                                        source_table_name: source_table_name,
                                                        dist_env:dist_env, 
                                                        dist_appid:dist_appid, 
                                                        dist_table_name: dist_table_name,
                                                        empty_dist_table: empty_dist_table,
                                                    }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
            
        })
    }
    
}