"use strict";
import axios from 'axios';
import request from './request'

export default {
    getProductInfo(filter){
        return new Promise( (resolve, reject) => {
            request.get('product_data', {
                'filter': filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    getKeywordsRanking(filter)    {
       return new Promise( (resolve, reject) => {
            request.get('product_data/keywords_ranking', {
                'filter': filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });  
    },
    getProductAuthorRanking()    {
       return new Promise( (resolve, reject) => {
            request.get('product_data/ranking/author', {}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });  
    },
    getQuestionLost(product) {
        return new Promise( (resolve, reject) => {
             request.get('product_analysis', {product: product}).then( data=>{
                 resolve(data);
             }, err => {
                 reject(err)
             });
         });
     }
}