"use strict";
/*文案宝典相关接口*/
import axios from 'axios';
import request from './request'

export default {
    // 获取文案列表
    getPhraseList(filter){
        return new Promise( (resolve, reject) => {
            request.get('phrase/books', {
                filters: filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    // 添加文案
    addPhrases(phrase){
        return new Promise( (resolve, reject) => {
            request.post('phrase/books', phrase).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    // 添加文案评价
    addVote(voteData){
        return new Promise( (resolve, reject) => {
            request.post('phrase/vote', voteData).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    // 获取票选期还未过去的文案
    getVoteList(filters){
        return new Promise( (resolve, reject) => {
            request.get('phrase/books_vote', filters).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    // 获取本周剩余可添加文案数据
    getPhraseNumForThisWeek(filters){
        return new Promise( (resolve, reject) => {
            request.get('phrase/books_left', filters).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    deletePhrase(id) {
        return new Promise( (resolve, reject) => {
            request.delete('phrase/books', {
                'book_id': id
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });  
    },

    // 修改投票人信息
    set_voter_gender(gender) {
        return new Promise( (resolve, reject) => {
            request.post('phrase/voter', {
                'gender': gender
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });  
    },

    // 获取投票人信息
    get_voter_info() {
        return new Promise( (resolve, reject) => {
            request.get('phrase/voter', {
                'params': {}
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });  
    },

    // 获取文案排名列表
    getPhraseRankList(filter){
        return new Promise( (resolve, reject) => {
            request.get('phrase/rank/phrase', {
                filters: filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    // 获取产品经理列表
    getPhraseManagerRankList(filter){
        return new Promise( (resolve, reject) => {
            request.get('phrase/rank/manager', {
                filters: filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
}
