"use strict";
import axios from 'axios';
import request from './request'

export default {
    getMaterialCategory(parent_id) {

        return new Promise( (resolve, reject) => {
            request.get('material_category',{parent_id:parent_id}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    getCategoryNames(ids){
        return new Promise( (resolve, reject) => {
            request.post('material_categorys/names',{ids:ids}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    getMaterialCategorys() {
        
        return new Promise( (resolve, reject) => {
            request.get('material_categorys').then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    addMaterialCategory(category_name, parent_id) {
        return new Promise( (resolve, reject) => {
            request.post('material_category',{category_name:category_name, parent_id:parent_id}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    updateMaterialCategory(category_name, category_id) {
        return new Promise( (resolve, reject) => {
            request.put('material_category',{category_name: category_name, category_id:category_id}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    deleteMaterialCategory(category_id){
        return new Promise( (resolve, reject) => {
            request.delete('material_category',{ category_id:category_id}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    }
}