"use strict";
import axios from 'axios';
import request from './request'

export default {
    // 获得日志列表
    getLogList(filter) {
        return new Promise( (resolve, reject) => {
            //console.log(filter)
            request.get('commit_log', {
                'filter': filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    // 获取操作日志详细返回值
    getLogDetail(id) {
        return new Promise( (resolve, reject) => {
            request.get('commit_log/detail', {
                'id': id
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

}