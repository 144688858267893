"use strict";
import axios from 'axios';
import request from '../request'

export default {

    getLogs(filter){
        return new Promise( (resolve, reject) => {
            request.get('product/qrcode/logs', {
                'filter': filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    // 以上是与产品出码日志直接相关的信息查询
}