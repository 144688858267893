"use strict";
/*素材上传相关接口*/
import request from './request'
import Util from '../utils/index'

export default {    
    upload(files, tiny) {
        return new Promise( (resolve, reject) => {
            var formData = new FormData();

            for (var i = 0; i < files.length; i++) {
                formData.append('files', files[i]);
            }

            request.post('assets?tiny=' + tiny, formData).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });  
    },
    upload_one(file, tiny) {
        return new Promise( (resolve, reject) => {
            var formData = new FormData();
            formData.append('file', file);

            request.post('assets?tiny=' + tiny, formData).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    uploadOneV2(file, options) {
        const formData = new FormData();
        formData.append('file', file);
        for (const key in options) {
            formData.append(key, options[key])
        }

        return request.post('assets/v2', formData);
    },
    upload_mox_video(file) {
        return new Promise( (resolve, reject) => {
            var formData = new FormData();
            formData.append('file', file);
            request.post('assets/mox_video', formData).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    find(filters, page_range) {
        return new Promise( (resolve, reject) => {
            let query = Object.assign(filters);
            query.page_index = page_range[0];
            query.page_size = page_range[1];
            console.log(query)

            request.get(`assets/v2?${Util.jsonToUrlParams(query)}`).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });         
    },
    delete(assets_id) {
        return new Promise( (resolve, reject) => {
            request.delete('assets', {
                id: assets_id
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });       
    }
}