export default {
    path: '/oss',
    title: '运营系统',
    component: (resolve) => require(['~/views/frame/frame.vue'], resolve),
    redirect: '/oss/assets',
    meta: {
        title: '首页',
        keepAlive: false
    },
    children: [
        // {
        //     path: '/oss/news/user_taggle/index',
        //     redirect: '/oss/tools/user_taggle/index',
        // },
        // {
        //     path: '/oss/tools/user_taggle/index',
        //     component: resolve => require(['~/views/oss/user_taggle/index.vue'], resolve),
        //     meta: {
        //         title: '用户标注',
        //         keepAlive: false
        //     },
        // },
        // {
        //     path: '/oss/live_data',
        //     name: 'live_data',
        //     component: resolve => require(['~/views/dataadmin/dataadmin.vue'], resolve),
        //     meta: {
        //         title: '实时数据',
        //         keepAlive: false,
        //     },
        //     menu: {
        //         icon: 'fa fa-line-chart',
        //         pos: 0
        //     }
        // },
        // {
        //     path: '/oss/m/live_data',
        //     name: 'live_data_m',
        //     component: resolve => require(['~/views/dataadmin/dataadmin_m.vue'], resolve),
        //     meta: {
        //         title: '实时数据',
        //         keepAlive: false,
        //     },
        //     menu: {
        //         icon: 'fa fa-line-chart',
        //         pos: 0
        //     }
        // },
        // {
        //     path: '/oss/news/schedule',
        //     name: 'schedule_v2',
        //     meta: {
        //         title: '排版系统',
        //         keepAlive: false
        //     },
        //     menu: {
        //         icon: 'fa fa-calendar',
        //         pos: 1
        //     },
        //     component: resolve => require(['~/views/oss/news/schedule_v2.vue'], resolve),
        // },
        // {
        //     path: '/oss/mp_apps/',
        //     name: 'mp_apps',
        //     meta: {
        //         title: '公号管理',
        //         keepAlive: false
        //     },
        //     menu: {
        //         icon: 'fa fa-weixin',
        //         pos: 2
        //     },
        //     // component: resolve => require(['~/views/oss/mp_apps/index.vue'], resolve),
        //     component: resolve => require(['~/views/components/PageMigrated.vue?name=mp_apps'], resolve),
        // },
        // {
        //     path: '/oss/mp_dataflow',
        //     name: 'mp_dataflow',
        //     meta: {
        //         title: '公号曲线',
        //         keepAlive: false
        //     },
        //     component: resolve => require(['~/views/oss/mp_apps/mp_dataflow_query.vue'], resolve),
        // },
        // {
        //     path: '/oss/mocha/product_manager',
        //     name: 'product_mgr',
        //     meta: {
        //         title: 'Mocha产品',
        //         keepAlive: false
        //     },
        //     menu: {
        //         icon: 'fa fa-medium',
        //         pos: 3
        //     },
        //     component: resolve => require(['~/views/oss/mocha/product_manager.vue'], resolve),
        // },
        // {
        //     path: '/oss/m/mocha/product_manager',
        //     name: 'm_product_mgr',
        //     meta: {
        //         title: 'Mocha产品',
        //         keepAlive: false
        //     },
        //     menu: {
        //         icon: 'fa fa-medium',
        //         pos: 3
        //     },
        //     component: resolve => require(['~/views/oss/mocha/product_manager_m.vue'], resolve),
        // },
        // {
        //     path: '/oss/news/product_data',
        //     name: 'product_data',
        //     meta: {
        //         title: 'Mocha数据',
        //         keepAlive: false
        //     },
        //     menu: {
        //         icon: 'fa fa-product-hunt',
        //         pos: 4
        //     },
        //     component: resolve => require(['~/views/oss/news/productData.vue'], resolve),
        // },
        // {
        //     path: '/oss/news/material',
        //     name: 'material',
        //     meta: {
        //         title: '文章数据',
        //         keepAlive: false,
        //         url: 'https://mp.mozigu.cn/#/mp/mp_data'
        //     },
        //     menu: {
        //         icon: 'fa fa-wpforms',
        //         pos: 5
        //     },
        //     // component: resolve => require(['~/views/oss/news/material.vue'], resolve),
        //     component: resolve => require(['~/views/components/PageMigrated.vue?name=material'], resolve),
        // },
        // {
        //     path: '/oss/read_data/',
        //     name: 'read_data',
        //     meta: {
        //         title: '阅读数据',
        //         keepAlive: false,
        //         url: 'https://mp.mozigu.cn/#/mp/mp_data'
        //     },
        //     menu: {
        //         icon: 'fa fa-eye',
        //         pos: 6
        //     },
        //     // component: resolve => require(['~/views/oss/news/read_data.vue'], resolve),
        //     component: resolve => require(['~/views/components/PageMigrated.vue?name=read_data'], resolve),
        // },
        {
            path: '/oss/assets',
            meta: {
                title: '素材上传',
                keepAlive: false
            },
            menu: {
                icon: 'fa fa-file-image-o',
                pos: 7
            },
            component: (resolve) => require(['~/views/assets/index.vue'], resolve),
            beforeEnter: (to, from, next) => {
                window.location.href = 'https://tool.mozigu.cn/';
            }
        }
        // {
        //     path: '/oss/m/assets',
        //     meta: {
        //         title: '素材上传',
        //         keepAlive: false
        //     },
        //     component: resolve => require(['~/views/assets/index_m.vue'], resolve),
        //     menu: {
        //         icon: 'fa fa-file-image-o',
        //         pos: 7
        //     }
        // },
        // {
        //     path: '/oss/tools',
        //     name: 'tools-box',
        //     meta: {
        //         title: '工具箱',
        //         keepAlive: false
        //     },
        //     menu: {
        //         icon: 'fa fa-inbox',
        //         pos: 8
        //     },
        //     component: resolve => require(['~/views/oss/tools.vue'], resolve),
        // },
        // {
        //     path: '/oss/news/forbidden',
        //     name: 'forbidden',
        //     meta: {
        //         title: '违规文章',
        //         keepAlive: false
        //     },
        //     component: resolve => require(['~/views/oss/news/forbidden.vue'], resolve),
        // },
        // {
        //     path: '/oss/mocha/edit_product',
        //     name: 'product_edit',
        //     meta: {
        //         title: '产品编辑',
        //         keeAlive: false
        //     },
        //     component: resolve => require(['~/views/oss/mocha/edit_product.vue'], resolve),
        // },
        // {
        //     path: '/oss/m/mocha/edit_product',
        //     name: 'product_edit_m',
        //     meta: {
        //         title: '产品编辑',
        //         keeAlive: false
        //     },
        //     component: resolve => require(['~/views/oss/mocha/edit_product_m.vue'], resolve),
        // },
        // {
        //     path: '/oss/mocha/product_test',
        //     name: 'product_test',
        //     meta: {
        //         title: '产品h5作图测试',
        //         keeAlive: false
        //     },
        //     component: resolve => require(['~/views/oss/mocha/product_test.vue'], resolve),
        // },
        // {
        //     path: '/oss/mocha/product_test/history',
        //     name: 'product_test_history',
        //     meta: {
        //         title: '产品h5作图测试历史',
        //         keeAlive: false
        //     },
        //     component: resolve => require(['~/views/oss/mocha/product_test_history.vue'], resolve),
        // },
        // {
        //     path: '/oss/mocha/product_test/history/stat',
        //     name: 'product_test_history_stat',
        //     meta: {
        //         title: '产品h5作图测试历史统计数据',
        //         keeAlive: false
        //     },
        //     component: resolve => require(['~/views/oss/mocha/product_test_history_stat.vue'], resolve),
        // },
        // {
        //     path: '/oss/mocha/product_test/manage',
        //     name: 'product_test_manage',
        //     meta: {
        //         title: '产品h5作图测试管理',
        //         keeAlive: false
        //     },
        //     component: resolve => require(['~/views/oss/mocha/product_test_manage.vue'], resolve),
        // },
        // {
        //     path: '/oss/mocha/product_log',
        //     name: 'product_log',
        //     meta: {
        //         title: '产品日志',
        //         keeAlive: false
        //     },
        //     component: resolve => require(['~/views/oss/mocha/product_log.vue'], resolve),
        // },
        // {
        //     path: '/oss/mocha/product/mps/fans_statistics',
        //     name: 'product_mps_fans_statistics',
        //     meta: {
        //         title: '公号涨粉统计',
        //         keeAlive: false
        //     },
        //     component: resolve => require(['~/views/oss/mocha/product/mps_fans_statistics.vue'], resolve),
        // },
        // {
        //     path: '/oss/mocha/product/mps/blank_qr',
        //     name: 'product_mp_blank_qr',
        //     meta: {
        //         title: '公号空二维码查询',
        //         keeAlive: false
        //     },
        //     component: resolve => require(['~/views/oss/mocha/product/blank_qr.vue'], resolve),
        // },
        // {
        //     path: '/m/ranking/mocha_author',
        //     meta: {
        //         title: '产品经理涨粉排行',
        //         keepAlive: false
        //     },
        //     component: resolve => require(['~/views/oss/mocha/ranking_mocha_author.vue'], resolve),
        // },

        // {
        //     path: '/oss/news/batch_create_materials',
        //     name: 'batch_create_materials',
        //     meta: {
        //         title: '批量创建文章',
        //         keepAlive: false
        //     },
        //     component: resolve => require(['~/views/oss/news/batch_create_materials.vue'], resolve),
        // },
        // {
        //     path: '/oss/pay_bill',
        //     name: 'pay_bill',
        //     meta: {
        //         title: '订单支付',
        //         keepAlive: false
        //     },
        //     component: resolve => require(['~/views/oss/news/pay_bill.vue'], resolve),
        // },
        // {
        //     path: '/oss/mocha/custom_message',
        //     name: 'mocha_custom_message',
        //     meta: {
        //         title: '客服消息',
        //         keepAlive: false
        //     },
        //     component: resolve => require(['~/views/oss/mocha/custom_message.vue'], resolve),
        // },
        // {
        //     path: '/oss/custom_message/manage',
        //     name: 'mocha_custom_message_manage',
        //     meta: {
        //         title: '墨客系统管理',
        //         keepAlive: false
        //     },
        //     component: resolve => require(['~/views/oss/mocha/custom_message_mgr.vue'], resolve),
        // },
        // {
        //     path: '/oss/mocha/service_mp_schedule',
        //     name: 'service_mp_schedule',
        //     meta: {
        //         title: '服务号二维码推送',
        //         keepAlive: false
        //     },
        //     component: resolve => require(['~/views/oss/mocha/service_mp_qrcode_push/index.vue'], resolve),
        // },
        // {
        //     path: '/oss/custom_message',
        //     name: 'mocha_create_custom_message',
        //     meta: {
        //         title: '墨客系统',
        //         keepAlive: false
        //     },
        //     // C:\Users\wbmoz\moapp_admin\web\src\views\oss\mocha\custom__message_new\custom_message.vue
        //     component: resolve => require(['~/views/oss/mocha/custom_message_new/custom_message.vue'], resolve),
        // },
        // {
        //     path: '/oss/mocha/uri_mgr',
        //     name: 'uri_mgr',
        //     meta: {
        //         title: '公号随机出码',
        //         keepAlive: false
        //     },
        //     component: resolve => require(['~/views/oss/mocha/uri_mgr.vue'], resolve),
        // },
        // {
        //     path: '/oss/tools/sign',
        //     name: '',
        //     meta: {
        //         title: '星座屋',
        //         keepAlive: false
        //     },
        //     component: resolve => require(['~/views/oss/sign/index.vue'], resolve),
        // },
        // {
        //     path: '/oss/tools/short_url',
        //     name: 'short_url',
        //     meta: {
        //         title: '长链接转短链接',
        //         keepAlive: false
        //     },
        //     component: resolve => require(['~/views/oss/short_url.vue'], resolve),
        // },
        // {
        //     path: '/oss/tools/news_title_ranking',
        //     name: 'news_title_ranking',
        //     meta: {
        //         title: '文章发布排行',
        //         keepAlive: false
        //     },
        //     component: resolve => require(['~/views/oss/news/news_title_ranking.vue'], resolve),
        // },
        // {
        //     path: '/oss/tools/miniprogram',
        //     name: 'miniprogram',
        //     meta: {
        //         title: '小程序管理',
        //         keepAlive: false
        //     },
        //     component: resolve => require(['~/views/oss/miniprogram/miniprogram.vue'], resolve),
        // },
        // {
        //     path: '/oss/tools/mo_ad',
        //     name: 'mo_ad',
        //     component: resolve => require(['~/views/tools/ad/index.vue'], resolve),
        //     meta: {
        //         title: '墨子谷广告系统',
        //         keepAlive: false
        //     },
        // },
        // {
        //     path: '/oss/tools/emoji_editor',
        //     name: 'emoji_editor',
        //     component: resolve => require(['~/views/tools/emoji_editor/index.vue'], resolve),
        //     meta: {
        //         title: 'emoji编辑器',
        //         keepAlive: false
        //     },
        // },
        // {
        //     path: '/oss/mocha/mp_fission',
        //     name: 'mp_fission',
        //     component: resolve => require(['~/views/oss/mocha/mp_fission/index.vue'], resolve),
        //     meta: {
        //         title: '服务号裂变',
        //         keepAlive: false
        //     },
        // },{
        //     path: '/oss/mocha/mp_tags_daily_stat',
        //     name: 'mp_tags_daily_stat',
        //     component: resolve => require(['~/views/oss/mp_apps/mp_tags_daily_stat.vue'], resolve),
        //     meta: {
        //         title: '公众号标签组数据统计',
        //         keepAlive: false
        //     },
        // },
        // {
        //     path: '/oss/tools/template_msg_push',
        //     name: 'template_msg_push',
        //     meta: {
        //         title: '服务号模板消息推送',
        //         keepAlive: false
        //     },
        //     component: resolve => require(['~/views/oss/news/template_msg_push.vue'], resolve),
        // },
        // {
        //     path: '/oss/tools/mp_news_sensitive_words_verify',
        //     name: 'mp_news_sensitive_words_verify',
        //     meta: {
        //         title: '公众号文章敏感词校验',
        //         keepAlive: false
        //     },
        //     component: resolve => require(['~/views/tools/mp_news_sensitive_words_verify.vue'], resolve),
        // },
        // {
        //     path: '/oss/tools/search_phone_number',
        //     name: 'search_phone_number',
        //     meta: {
        //         title: '手机号查询',
        //         keepAlive: false
        //     },
        //     component: resolve => require(['~/views/tools/search_phone_number.vue'], resolve),
        // },
    ]
};
