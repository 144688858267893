"use strict";
import axios from 'axios';
import request from './request'

export default {
    getDomainInfo(filter){
        return new Promise( (resolve, reject) => {
            request.get('domain', {
                'filter': filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    addNewDomain(items) {
        return new Promise( (resolve, reject) => {            
            request.post('domain', items).then( data=>{
                resolve(data);
            }, err => {
                reject(err);
            });
        });
    },
    deleteDomain(items) {
        return new Promise( (resolve, reject) => {            
            request.delete('domain', {'data':items}).then( data=>{
                resolve(data);
            }, err => {
                reject(err);
            });
        });
    },
    ChangeDomain(items) {
        return new Promise( (resolve, reject) => {            
            request.put('domain', items).then( data=>{
                resolve(data);
            }, err => {
                reject(err);
            });
        });
    },
    // -------------- 域名管理 --------------
    
    createDomains(data) {
        return new Promise( (resolve, reject) => {
            request.post('domain_mgr', data).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    updateDomains(data) {
        return new Promise( (resolve, reject) => {
            request.put('domain_mgr', data).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    deleteDomains(domain) {
        return new Promise( (resolve, reject) => {
            request.delete('domain_mgr', {domain: domain}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    getWatchDomains() {
        return new Promise( (resolve, reject) => {
            request.get('domain_watch').then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    }
}