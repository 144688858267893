"use strict";
import axios from 'axios';
import request from './request'

export default {
  getAnalysisData(filters) {
    return new Promise( (resolve, reject) => {
      request.post('positive_discipline/api/analysis/getData',filters).then( data=>{
          resolve(data);
      }, err => {
          reject(err)
      });
  });
  }
}
