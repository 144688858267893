"use strict";
import axios from 'axios';
import request from './request'
import utils from '~/utils/'

export default {
    find(filters) {
        return request.get('constellation_article_data', {
            filters
        });
    },
    get_product_info(pid){
        return request.get('constellation_article_data/'+pid, {});
    },
    get_date_subdivision_data(title) {
        return request.get('constellation_article_data/date_subdivision_data', {
            title
        });
    },
    get_sub_constellation_article_data(title) {
        return request.get('constellation_article_data/sub_constellation_article_data', {
            title
        });
    },
    add_one(info){
        return new Promise((resolve, reject) => {
            request.post('constellation_article_data',info).then(data => {
                resolve(data);
            }, err => {
                reject(err);
            });
        });
    },
    delete_one(id){
        return new Promise((resolve, reject) => {
            request.delete('constellation_article_data', {'id': id}).then(data => {
                resolve(data);
            }, err => {
                reject(err);
            });
        });
    },
    update_one(pid, info){
        return new Promise((resolve, reject) => {
            request.put('constellation_article_data/'+pid, info).then(data => {
                resolve(data);
            }, err => {
                reject(err);
            });
        });
    },
}