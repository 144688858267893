"use strict";
import axios from 'axios';
import request from './request'
import config from '~/config'
import user from './user.js'

export default {
	    getAllQrocde(mpid) {
        return new Promise( (resolve, reject) => {
            request.get('api_params_qrcode',{'mpid':mpid}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    insertQrocde(mpid, msg) {
        return new Promise( (resolve, reject) => {
            request.post('api_params_qrcode',{'mpid':mpid,'msg':msg}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
        deleteQrocde(mpid, msg) {
        return new Promise( (resolve, reject) => {
            request.delete('api_params_qrcode',{'mpid':mpid,'msg':msg}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },


    get(path, params) {
        params = params || {};
        var host = config.apiHost;
        var result = this.host_and_path_handle(host,path);
        host = result['host'];
        path = result['path'];
        return new Promise( (resolve, reject) => {
            axios.get(host + path, {
                'params': params
            }).then( res => {
                    resolve(res);
            }, err => {
                if (err.response.status == 511) {
                    user.logout();
                } else {                    
                    reject({
                        ret: -1,
                        msg: err,
                        data: ''
                    })
                }
                
            }).catch(
    res => {
        console.log('error data::', res);
    }
);;
        }); // Promise
    },

    host_and_path_handle(host,path){
        var is_match = path.indexOf('video');
        if (is_match == 0){
            host ='http://video.mozigu.cn/api';
            path = path.replace('video', '')
        };
        return {
            "host":host,
            "path":path
        }
    },
    downloadQrocde(url) {
        return new Promise( (resolve, reject) => {
            this.get('api_download_qrcode',{'url':url}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    }
}