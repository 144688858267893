"use strict";
import axios from 'axios';
import request from './request'

/*
    手动为用户打标签
    author:GouPeng
*/
export default {

    // 获取近期活跃用户列表
    getLatestAcitveUserListLength(filters,history){
        return new Promise( (resolve, reject) => {
            request.post('user_taggle_manually/latest_active_users_length', {
                'filters': filters,
                "history":history,
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    // 获取近期活跃用户列表
    getLatestAcitveUserlist(filters,history){
        return new Promise( (resolve, reject) => {
            request.post('user_taggle_manually/latest_active_users', {
                'filters': filters,
                "history":history,
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    getMyTagsHistory(data){
        return new Promise( (resolve, reject) => {
            request.get('user_taggle_manually/tags_history', {
                'mpid': mpid
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    getMyTaggleHistory(data){
        return new Promise( (resolve, reject) => {
            request.get('user_taggle_manually/taggle_history', {
                'mpid': mpid
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    addTagsToUsers(data){
        return new Promise( (resolve, reject) => {
            request.post('user_taggle_manually/taggle', {
                'data': data
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    addTagsToUsersBatch(data){
        return new Promise( (resolve, reject) => {
            request.post('user_taggle_manually/taggle_batch',data).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },    
}