'use strict';
import axios from 'axios';
import config from '~/config';
import user from './user.js';

function RequestPromise(req) {
    /*
        Network Promise 统一处理

        参数说明：
            req : Network Promise 对象
    */
    return new Promise((resolve, reject) => {
        req.then(
            (res) => {
                if (res.data.ret === 0 || (res.data.code && res.data.code.toLowerCase() == 'ok')) {
                    resolve(res.data.data);
                } else {
                    reject(res.data);
                }
            },
            (err) => {
                if (err.response && err.response.status === 511) {
                    user.logout();
                }
                reject({
                    ret: -1,
                    msg: err,
                    data: ''
                });
            }
        ).catch((res) => {
            console.log('error data::', res);
        });
    });
}

export class Request {
    /*
        网络请求 封装
    */
    constructor(host, options) {
        this.HOST = typeof host === 'string' ? host : config.apiHost;
        this.options = options || {};
    }

    get(path, params, data) {
        /*
            HTTP GET
        */
        params = params || {};
        return RequestPromise(
            axios.get(this.HOST + path, {
                params: params,
                data: data,
                headers: this.options.headers || {},
                timeout: 30000
            })
        );
    }

    post(path, params, timeout = 120000) {
        /*
            HTTP POST
        */
        params = params || {};
        return RequestPromise(axios.post(this.HOST + path, params, { timeout: timeout }));
    }

    delete(path, params = null, data = null) {
        /*
            HTTP DELETE
        */
        params = params || {};
        return RequestPromise(
            axios.delete(this.HOST + path, {
                params: params,
                data: data
            })
        );
    }

    put(path, params) {
        /*
            HTTP DELETE
        */
        params = params || {};
        return RequestPromise(axios.put(this.HOST + path, params));
    }
}

export default new Request();
