"use strict";
import axios from 'axios';
import request from '../request'

export default {
    
    getSingleProductInfo(filter){
        return new Promise( (resolve, reject) => {
            request.get('product/manage', {
                'filter': filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    addProduct(data){
        return new Promise( (resolve, reject) => {
            request.post('product/manage', {
                'data': data
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    changeProduct(filters){
        return new Promise( (resolve, reject) => {
            request.put('product/manage', {
                'filters': filters
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    deleteProduct(data){
        return new Promise( (resolve, reject) => {
            request.delete('product/manage', {
                'data': data
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    // 以上产品增删改接口
}