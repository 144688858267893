"use strict";
import axios from 'axios';
import request from './request'

export default {
    getMochaWebError(filter){
            return new Promise( (resolve, reject) => {
                console.log('filter',filter);
                request.get('mocha_web/error', {
                    'filter':  filter                                      
                }).then( data=>{
                    resolve(data);
                }, err => {
                    reject(err)
                });
            });
        },
}