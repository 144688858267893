"use strict";
/*
    代码管理相关接口
 */

import request from './request'

export default {
    getProjectInfo(app_type, appid, signature) {
        return new Promise( (resolve, reject) => {
            request.get('code/project', {
                app_type: app_type,
                appid: appid,
                signature: signature,
            }).then( data=>{
                resolve(data);
            }, err=>{
                reject(err);
            });    
        }); //promise
    },
    setSourceCode(app_type, appid, signature, file_name, code) {
        return new Promise( (resolve, reject) => {
            request.post('code/project/source_file', {
                app_type: app_type,
                appid: appid,
                signature: signature,
                file_name: file_name,
                code: code
            }).then( data=>{
                resolve(data);
            }, err=>{
                reject(err);
            });    
        }); //promise
    },
    getSourceCode(app_type, appid, signature, file_name, env) {
        return new Promise( (resolve, reject) => {
            request.get('code/project/source_file', {
                app_type: app_type,
                appid: appid,
                signature: signature,
                file_name: file_name,
                env: env
            }).then( data=>{
                resolve(data);
            }, err=>{
                reject(err);
            });    
        }); //promise
    },
    delSourceCode(app_type, appid, signature, file_name) {
        return new Promise( (resolve, reject) => {
            request.delete('code/project/source_file', {
                app_type: app_type,
                appid: appid,
                signature: signature,
                file_name: file_name
            }).then( data=>{
                resolve(data);
            }, err=>{
                reject(err);
            });    
        }); //promise
    },
    build(appid, env, h5_mode) {
        return new Promise( (resolve, reject) => {
            request.post('code/project/builder', {
                appid: appid,
                env: env,
                h5_mode: h5_mode
            }).then( data=>{
                resolve(data);
            }, err=>{
                reject(err);
            });    
        }); //promise       
    }    
}