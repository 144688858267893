"use strict";
import axios from 'axios';
import request from './request'

export default {
    getUserData(filter, projection){
            return new Promise( (resolve, reject) => {
                request.get('api_user_admin', {
                    'filter':  filter,
                    'projection': JSON.stringify(projection)
                }
                ).then( data=>{
                    resolve(data);
                }, err => {
                    reject(err)
                });
            });
        },
    updateLoginState(uid, isAllowLogin){
        var post_data = {
            'uid':uid,
            'isAllowLogin':isAllowLogin
        }

        // JSON.stringify(post_data)
        return new Promise( (resolve, reject) => {
            request.put('api_user_admin', {
                'post_data': post_data
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    get_admin_uids() {
        // 获取开发人员的uid。暂时没有后端接口
        let uids = new Set([
            '5b0bab28f4a8fe7646fde3a8',
            '5af1a4ecf4a8fe0466894e92',
            '5af01149f4a8fe263746789a',
            '5b5f4355393e345d881fc1f7',
            '5b5fbd2c393e343bc9bb87c2',
            '5b5fc33c393e344a4f4dd646',
            '5b6000a9393e34738a37e25a',
            '5bac3447479833221dca05be',
            '5b5a8e0f14506211c9c3c789',
        ]);
        return uids;
    }
}
