"use strict";
import axios from 'axios';
import request from './request'

export default {
	get_adtag(filters){
		return new Promise( (resolve, reject) => {
            console.log('filters',filters)
            request.get(`astrology/adtag`,{'filters':filters}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
	},
    get_product_name(pro_id){
        return new Promise( (resolve, reject) => {
            request.get(`astrology/adtag/product_name`,{'pro_id':pro_id}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
	add_adtag(data){
		return new Promise( (resolve, reject) => {
            console.log('post  -data',data)
            request.post(`astrology/adtag`,data).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
	},
    del_adtag(adtag_id){
        return new Promise( (resolve, reject) => {
            console.log('adtag_id',adtag_id)
            request.get(`astrology/del_adtag`,{'adtag_id':adtag_id}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    updateAdtag(adtag_id,params){
        return new Promise( (resolve, reject) => {
            console.log('adtag_id',adtag_id)
            request.put(`astrology/adtag/${adtag_id}`,params).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
	get_ori_price(product_name){
		return new Promise( (resolve, reject) => {
            request.get(`astrology/product`, {
                'data': data
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    get_order_stat_info(filters){
        return new Promise( (resolve, reject) => {
            request.get(`astrology/order/stat`, {
                'filters': filters
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    get_order_stat_summary(filters){
        return new Promise( (resolve, reject) => {
            request.get(`astrology/order/summary`, {
                'filters': filters
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    get_order_stat_details(filters){
        return new Promise( (resolve, reject) => {
            request.get(`astrology/order/details`, {
                'filters': filters
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    get_adtags_by_product_name(product_name){
        return new Promise( (resolve, reject) => {
            request.get(`astrology/order/adtags`, {
                'filters': {
                    'name':product_name
                }
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    get_adtags_by_studio(studio){
        return new Promise( (resolve, reject) => {
            request.get(`astrology/order/adtags_by_studio`, {
                'filters': {
                    'studio':studio
                }
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    get_adtag_list(filters){
        return new Promise( (resolve, reject) => {
            request.get(`astrology/adtags_list`, {
                'filters':filters
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    // 王冰的接口
    getProducts(filter) {
        return new Promise( (resolve, reject) => {
            console.log(filter)
            request.get('astrology/product', {
                filter: filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    createProduct(data) {
        console.log(data)
        return new Promise( (resolve, reject) => {
            request.post('astrology/product', data).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    editProduct(data) {
        console.log(data)
        return new Promise( (resolve, reject) => {
            request.put('astrology/product', data).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    deleteProduct(id) {
        console.log(id)
        return new Promise( (resolve, reject) => {
            request.delete('astrology/product', {id: id}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    // deleteProduct(data) {
    //     console.log(data)
    //     return new Promise( (resolve, reject) => {
    //         request.post('astrology/product', data).then( data=>{
    //             resolve(data);
    //         }, err => {
    //             reject(err)
    //         });
    //     });
    // },

    getCustomerInfo(filter) {
        return new Promise( (resolve, reject) => {
            request.get('astrology/get_customer', {
                filter: filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    getProductNames() {
        return new Promise( (resolve, reject) => {
            request.get('astrology/product/names').then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    getProductNamesPrice() {
        return new Promise( (resolve, reject) => {
            request.get('astrology/product/names_price').then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    get_data_history(filters){
        console.log('filter',filters)
        return new Promise( (resolve, reject) => {
            request.get('astrology/data_history', {'filters':filters}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    get_income_today(filters){
        console.log('filter',filters)
        return new Promise( (resolve, reject) => {
            request.get('astrology/data_today', {'filters':filters}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    get_total_income(appid){
        console.log('appid', appid)
        return new Promise( (resolve, reject) => {
            request.get('astrology/miniprogram/total_income', {'appid':appid}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    get_today_income(appid){
        console.log('appid', appid)
        return new Promise( (resolve, reject) => {
            request.get('astrology/miniprogram/today_income', {'appid':appid}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    addUser(data){
        return new Promise( (resolve, reject) => {
            request.post(`astrology/mxx/user`,{'post_data':data}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    editUser(id,data){
        return new Promise( (resolve, reject) => {
            request.put(`astrology/mxx/user`,{'id':id,'put_data':data}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    deleteUser(id){
        return new Promise( (resolve, reject) => {
            request.delete(`astrology/mxx/user`,{'id':id}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    findUser(data){
         return new Promise( (resolve, reject) => {
            request.get(`astrology/mxx/user`,{'filters':data}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    addCompany(data){
        return new Promise( (resolve, reject) => {
            request.post(`astrology/mxx/company`,{'post_data':data}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    editCompany(id,data){
        return new Promise( (resolve, reject) => {
            request.put(`astrology/mxx/company`,{'id':id,'put_data':data}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    deleteCompany(id){
        return new Promise( (resolve, reject) => {
            request.delete(`astrology/mxx/company`,{'id':id}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    findCompany(data){
         return new Promise( (resolve, reject) => {
            request.get(`astrology/mxx/company`,{'filters':data}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    getCompanyList(data){
         return new Promise( (resolve, reject) => {
            request.get(`astrology/mxx/company_info`,{'filters':data}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    editCompanySerialNumber(id){
        return new Promise( (resolve, reject) => {
            request.put(`astrology/mxx/company_info/serial_number`,{'id':id}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    getStudios(filters) {
        return new Promise( (resolve, reject) => {
            request.get('astrology/studio', {
                filters: filters
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    getStudioList() {
        return new Promise( (resolve, reject) => {
            request.get('astrology/studio_list', {
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    addStudios(data) {
        return new Promise( (resolve, reject) => {
            request.post('astrology/studio', data).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    updateStudios(data) {
        return new Promise( (resolve, reject) => {
            request.put('astrology/studio', data).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    deleteStudios(id) {
        return new Promise( (resolve, reject) => {
            request.delete('astrology/studio', {studio_id: id}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    studioAdtag(filters) {
        return new Promise( (resolve, reject) => {
            request.get('astrology/studio_adtag', {
                filters: filters
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    isAdmin() {
        return new Promise( (resolve, reject) => {
            request.get('astrology/admin').then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    }
}
