export default {
    path: '/astrology',
    title: '星盘后台系统',
    component: resolve => require(['~/views/frame/frame.vue'], resolve),
    redirect: '/astrology/live_data',
    meta: {
      title: '星盘',
      keepAlive: false,
    },
    children: [
      {
        path: '/astrology/live_data',
        component: resolve => require(['~/views/astrology/live_data.vue'], resolve),
        meta: {
          title: '实时数据',
          keepAlive: false,
        },
        menu:{
            icon: 'fa fa-line-chart', 
            pos: 0
        }
      },
      {
        path: '/astrology/product',
        meta: {
          title: '产品管理',
          keepAlive: false
        },
        menu: {
            icon: 'fa fa-medium',
            pos: 0
        },
        component: resolve => require(['~/views/astrology/product_mgr.vue'], resolve),
      },
      {
        path: '/astrology/channel',
        meta: {
          title: '渠道管理',
          keepAlive: false
        },
        menu: {
            icon: 'fa fa-road',
            pos: 0
        },
        component: resolve => require(['~/views/astrology/channel_mgr.vue'], resolve),
      },
      {
        path: '/astrology/order',
        meta: {
          title: '订单统计',
          keepAlive: false
        },
        menu: {
            icon: 'fa fa-pencil-square-o',
            pos: 0
        },
        component: resolve => require(['~/views/astrology/order_stat_index.vue'], resolve),
      },
      {
        path: '/astrology/m/order',
        meta: {
          title: '订单统计',
          keepAlive: false
        },
        menu: {
            icon: 'fa fa-pencil-square-o',
            pos: 0
        },
        component: resolve => require(['~/views/astrology/order_stat_index_m.vue'], resolve),
      },
      {
        path: '/astrology/customer',
        meta: {
          title: '客户信息',
          keepAlive: false
        },
        menu: {
            icon: 'fa fa-address-card',
            pos: 0
        },
        component: resolve => require(['~/views/astrology/customer_info.vue'], resolve),
      },
      {
        path: '/astrology/m/customer',
        meta: {
          title: '客户信息',
          keepAlive: false
        },
        menu: {
            icon: 'fa fa-address-card',
            pos: 0
        },
        component: resolve => require(['~/views/astrology/customer_info_m.vue'], resolve),
      },
      {

        path: '/astrology/data_compare',
        component: resolve => require(['~/views/astrology/data_compare.vue'], resolve),
        meta: {
          title: '数据对比',
          keepAlive: false,
        },
        menu:{
            icon: 'fa fa-line-chart', 
            pos: 0
        }

      },
      {
        path: '/astrology/studio',
        component: resolve => require(['~/views/astrology/studio.vue'], resolve),
        meta: {
          title: '工作室管理',
          keepAlive: false,
        },
        menu:{
            icon: 'fa fa-users', 
            pos: 0
        }

      },
      {
        path: '/astrology/cooperation',
        component: resolve => require(['~/views/astrology/cooperation_index.vue'], resolve),
        meta: {
          title: '对外合作',
          keepAlive: false,
        },
        menu:{
            icon: 'fa fa-globe', 
            pos: 0
        }

      },
    ]
}