"use strict";
import axios from 'axios';
import request from './request'

export default {
  get_data_stat_sum(filter) {
    /* 
      filter: {
        app: app, 默认为登录app
        begin_time_stamp: 开始时间戳，默认当前时间
        end_time_stamp: 截止时间戳，默认等于开始时间戳
        pattern: 筛选的正则表达式
      }
    */
    // console.log('filter',typeof(filter.begin_time_stamp))

    return request.get('data_stat', filter);
  }
}
