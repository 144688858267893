export default  {
  path: '/miniprogram',
  title: 'MoApp',
  component: resolve => require(['~/views/frame/frame.vue'], resolve),
  redirect: '/miniprogram/home',
  meta: {
    title: '开发管理',
    keepAlive: false,
  },
  children: [
    {
      path: '/miniprogram/home',
      meta: {
        title: '首页',
        keepAlive: false
      },
      menu:{
        icon: 'fa fa-home',
        pos: 0
      },
      component: resolve => require(['~/views/miniprogram/home/home.vue'], resolve),
    },
    {
       path: '/miniprogram/develop',
       name: 'develop',
       meta: {
         title: '代码编辑',
         keepAlive: true
       },
       menu: {
        icon: 'fa fa-code',
        pos: 1
      },
      component: resolve => require(['~/views/miniprogram/develop/editor.vue'], resolve),
    },
    {
       path: '/miniprogram/component_market',
       name: 'component_market',
       meta: {
         title: '模块市场',
         keepAlive: true
       },
       menu: {
        icon: 'fa fa-jsfiddle',
        pos: 2
      },
      component: resolve => require(['~/views/miniprogram/develop/components_market.vue'], resolve),
    },
    {
       path: '/miniprogram/widget_market',
       name: 'widget_market',
       meta: {
         title: '控件市场',
         keepAlive: true
       },
       menu: {
        icon: 'fa fa-cubes',
        pos: 3
      },
      component: resolve => require(['~/views/miniprogram/develop/controls_market.vue'], resolve),
    },
    {
       path: '/miniprogram/tutorial',
       name: 'tutorial',
       meta: {
         title: '参考文档',
         keepAlive: true
       },
       menu: {
        icon: 'fa fa-book',
        pos: 3
      },
      component: resolve => require(['~/views/miniprogram/tutorial/tutorial_component.vue'], resolve),
    },
    {
      path: '/miniprogram/data',
      name: 'data',
      meta: {
        title: '数据统计',
        keepAlive: false
      },
      menu: {
        icon: 'fa fa-line-chart',
        pos: 4
      },
      component: resolve => require(['~/views/miniprogram/data/data.vue'], resolve),
    },
    {
      path: '/miniprogram/manager',
      name: 'manager',
      meta: {
        title: '项目成员',
        keepAlive: false
      },
      menu: {
        icon: 'fa fa-user-plus',
        pos: 4
      },
      component: resolve => require(['~/views/miniprogram/manager/dev_manager.vue'], resolve),
    },
    {
      path: '/miniprogram/database',
      name: 'database',
      meta: {
        title: '数据库',
        keepAlive: false
      },
      menu: {
        icon: 'fa fa-database',
        pos: 3
      },
      component: resolve => require(['~/views/miniprogram/database/database.vue'], resolve),
    },
    {
      path: '/miniprogram/cms',
      name: 'cms',
      meta: {
        title: '内容管理',
        keepAlive: false
      },
      component: resolve => require(['~/views/miniprogram/cms/cms.vue'], resolve),
    },
    {
      path: '/miniprogram/test',
      name: 'test',
      meta: {
        title: '测试帅气表',
        keepAlive: false
      },
      component: resolve => require(['~/views/miniprogram/database/handsontable.vue'], resolve),
    },
    {
      path: '/miniprogram/positive_discipline/usage',
      name: 'positive_discipline_usage',
      meta: {
        title: '正面管教小程序用户数据',
        keepAlive: false
      },
      component: resolve => require(['~/views/miniprogram/positive_discipline/usage.vue'], resolve)
    }
  ],
}