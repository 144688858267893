"use strict";
import axios from 'axios';
import request from './request'

export default {
    getMemData() {
        return new Promise( (resolve, reject) => {
            request.get('develop/mem').then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    addMember(wechatid) {
        return new Promise( (resolve, reject) => {
            request.post('develop/mem', {action:'add', wechatid: wechatid}).then( data=>{
                resolve(data);
            }, err => {
                console.error(err)
                err.err_title = '添加体验者失败'
                if (err.ret == -1) {
                    err.err_content = '服务器异常'
                }
                else if (err.ret == -2) {
                    var err_table = {
                        '-1': '系统繁忙',
                        '85001': '微信号不存在或微信号设置为不可搜索',
                        '85002': '小程序绑定的体验者数量达到上限',
                        '85003': '微信号绑定的小程序体验者达到上限',
                        '85004': '微信号已经绑定'
                    }
                    err.err_content = err_table[err.data.errcode]
                }
                else {
                    err.err_content = '系统错误'
                }
                reject(err)
            });
        });        
    },

    delMember(item) {
        return new Promise( (resolve, reject) => {
            request.post('develop/mem', {action:'del', userstr: item}).then( data=>{
                resolve(data);
            }, err => {
                console.error(err)
                err.err_title = '删除体验者失败'
                if (err.ret == -1) {
                    err.err_content = '服务器异常'
                }
                else if (err.ret == -2) {
                    var err_table = {
                        '-1': '系统繁忙'
                    }
                    err.err_content = err_table[err.data.errcode]
                }
                else {
                    err.err_content = '系统错误'
                }                
                reject(err)
            });
        });         
    }
}