"use strict";
import axios from 'axios';
import request from './request'


/**
* 正面管教查询接口
*/

export default {
    find(filter) {
        return new Promise( (resolve, reject) => {
            request.get('mp/user',filter).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
}