"use strict";
import axios from 'axios';
import request from './request'

export default {
  create_page(page_data) {
    return new Promise((resolve, reject) => {
      console.log('page_data', page_data);
      request.post(`page_create`, page_data).then(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  },
  get_code(modify_url) {
    return new Promise((resolve, reject) => {
      console.log('modify_url', modify_url);
      request.get(`page_create`, { 'modify_url': modify_url }).then(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  },
  upload_audios(file_name, cmd, files) {
    return new Promise((resolve, reject) => {
      var formData = new FormData();
      for (let file of files) {
        formData.append('files', file);
      };
      request.post(`tools/audio?file_name=${file_name}&cmd=${cmd}`, formData).then(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  },
  upload_palm_img(file) {

  },
  getProcess(id, time) {
    return new Promise((resolve, reject) => {
      request.get(`app_income/${id}/${time}`).then((data) => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  },
  toExport(datas) {
    return new Promise((resolve, reject) => {
      request.post(`app_income/export`, datas).then(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  },
}