"use strict";
import axios from 'axios';
import request from '../request'

export default {
    

    getProductTargetMp(filter){
        return new Promise( (resolve, reject) => {
            request.get('product/target_mp', {
                'filter': filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    getAppName(filter){
        return new Promise( (resolve, reject) => {
            request.get('product/appname', {
                'filter': filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    
    getSingleProductDataflow(filter){
        return new Promise( (resolve, reject) => {
            request.get('product/dataflow', {
                'filter': filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    getApps(filter){
        return new Promise( (resolve, reject) => {
            request.get('product/wxapps', {
                'filter': filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    

    getImage(filter){
        return new Promise( (resolve, reject) => {
            request.get('product/image', {
                'filter': filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    getExtraInfo(filter){
        return new Promise( (resolve, reject) => {
            request.get('product/extra', {
                'filter': filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    
}