"use strict";
import axios from 'axios';
import request from './request'


export default {

    getShortUrl(long_url){
        return new Promise( (resolve, reject) => {
            request.get('short_url', {
                'long_url': long_url
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
}