"use strict";
import axios from 'axios';
import request from './request'

export default {
    getAppList() {
        return request.get('apps', {});
    },
    getAppInfo(appid) {
        return request.get('app/'+appid);
    },
    getDevelopers(appid) {
        return request.get('developers/'+appid);
    },
    requestAuth() {
        return new Promise( (resolve, reject) => {
            request.get('wxmini/pre_auth', {}).then( data=>{
                console.log(data)
                let redirect_uri = escape(location.origin + '/api/wxmini/auth');
                let url = `https://mp.weixin.qq.com/cgi-bin/componentloginpage?component_appid=${data.appid}&pre_auth_code=${data.pre_auth_code}&redirect_uri=${redirect_uri}&auth_type=2`;
                location.href = url;
                resolve(data);  // 调用没实际意义，只是什么……
            }, err=>{
                console.log('request auth fail!');
                console.error(err);
                reject(err);
            });
        }); //promise
    },
    createApp(appInfo) {
        return request.post('app/wechat', appInfo)
    },
    createWechatH5App(appInfo) {
        return request.post('app/wechat_h5', appInfo)
        // function fff () {
        //     return new Promise(function(resolve, reject) {
        //         resolve();
        //     });
        // }
        // let x = new Promise( (resolve, reject) => {
        //     fff().then( data=>{
        //         resolve({
        //             aid: 'xxxxxxxxxxxx'
        //         });
        //     }, err => {
        //         reject(err)
        //     });
        // });
        // return x
    },
    getAllAppList(filter) {
        return new Promise( (resolve, reject) => {
            request.get('moapp/apps', {
                'filter': filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    update_app(filter, appid) {
        // console.log('appid',appid)
        return new Promise( (resolve, reject) => {
            request.put('moapp/apps/'+ appid,filter).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    }
}