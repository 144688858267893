import { Request } from './request';
// const BASE_URL =
//     process.env.NODE_ENV == 'development'
//         ? 'https://dev.scf.mozigu.net/release/send_msg'
//         : 'https://dev.scf.mozigu.net/release/send_msg';
const BASE_URL =
    process.env.NODE_ENV == 'development'
        ? 'https://dev.scf.mozigu.net/release/send_msg'
        : 'https://api.scf.mozigu.cn/release/send_msg';
const request = new Request(BASE_URL);

// 模板消息推送相关接口

export default {
    /**
     * @description 获取历史发送消息
     * @param {Object} data
     * @param {Number} data.page_no
     * @param {Number} data.page_size
     * @param {Number} data.start_time 开始时间 时间戳
     * @param {Number} data.end_time 结束时间 时间戳
     * @param {Number} data.status 0 未发送 1 发送中 2 已发送
     */
    getMsgList(data) {
        return request.get('/send_info', data);
    },
    /**
     * @description 获取模板消息
     * @param {String} appid
     * @returns
     */
    getTemplateList(appid) {
        return request.get(`/${appid}/template`);
    },
    getOpenid(appid) {
        return request.get(`/${appid}/openid`);
    },
    /**
     * @description 获取公众号粉丝数
     * @param {String} appid
     * @returns
     */
    getMpFans(appid) {
        return request.get(`/${appid}/total`);
    },
    /**
     *
     * @param {Object} data
     * @param {String} data.uid 用户id
     * @param {String} data.appid 所选公众号的appid
     * @param {String} data.app_name 公众号名称
     * @param {Number} data.target_num 粉丝数
     * @param {Number} data.send_time 发送时间 时间戳
     * @param {String} data.template_id
     * @param {String} data.template_title
     * @param {Object} [data.records]
     * @param {Number} data.skip_type 跳转类型 0 网页 1 小程序
     * @param {String} [data.url]
     * @param {String} [data.skip_appid]
     * @param {String} [data.pagepath]
     * @returns
     */
    addMsg(data) {
        data.send_time = data.send_time / 1000;
        return request.post('/send_info', data);
    },
    /**
     *
     * @param {Object} data
     * @param {Number} data.send_info_id
     * @param {String} data.uid
     * @returns
     */
    deleteMsg(data) {
        return request.delete(`/send_info/${data.send_info_id}`, null, { uid: data.uid });
    },
    /**
     * @description 参数同addMsg
     * @param {*} data
     * @returns
     */
    updateMsg(data, id) {
        data.send_time = data.send_time / 1000;
        return request.put(`/send_info/${id}`, data);
    },
    /**
     * @param {String} uid
     * @returns
     */
    checkAdmin(uid) {
        return request.get(`/admin/${uid}`);
    }
};
