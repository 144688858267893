"use strict";
import request from './request'

export default {
    // 获取cms表格列表
    getCmsTables() {
        return request.get('table', {});
    },
    // 获取表数据
    getCmsTableData(tid, begin, count) {
        return new Promise( (resolve, reject)=>{
            let tables = {
                'tab1': [],
                'tab2': []
            };

            for(let i=0; i<470; ++i) {
                tables.tab1.push({
                    'id': i,
                    'title': '#'+(i+1),
                    'attr3': '一二三四五六七八九十一二三四五六七八九十一二三四五六七八九十attr3_' + (i+1),
                    'attr4': 'attr4_' + (i+1),
                    'attr5': 'attr5_' + (i+1)
                });
            }

            for(let i=0; i<20; ++i) {
                tables.tab2.push({
                    'id': i,
                    'title': '书籍#'+(i+1),
                    'attr3': 'attr3_' + (i+1)
                });
            }

            resolve({
                totalCount: tables[tid].length,
                data: tables[tid].slice(begin, begin+count)
            });
        });
        //return request.get('apps', {});
    },
    // 判断是否是系统库：系统为每个app自动分配了一个“资源库”，用来管理图片、音频、视频等素材
    isSysLib(tid) {
        return tid == '5b02febbf4a8fe759fa7af4d'
    },
    addTable(name, props) {
        return request.post('table', {
            name: name,
            props: props
        });
    },
    delTable(tid) {
        return request.delete('table', {
            tid: tid
        });
    }
}