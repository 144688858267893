"use strict";
/*
    微信公众号客服消息相关接口
*/
import axios from 'axios';
import request from './request'
import utils from '~/utils/'

export default {
    getCustomUserCount(appids) {
        return new Promise( (resolve, reject) => {            
            request.get('mocha/custom_message/user', {
                appids: appids.join(',')
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    getCustomUserCountV2(appids, tags_filter) {
        return new Promise( (resolve, reject) => {            
            request.post('mocha/custom_message/user_count', {
                appids: appids.join(','), tags_filter: tags_filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    createCustomMessage(appids, pub_datetime, msg_type, content, target_count, tags_filter) {
        return new Promise( (resolve, reject) => {            
            request.post('mocha/custom_message', {
                appids: appids,
                pub_datetime: pub_datetime,
                msg_type: msg_type,
                content: content,
                target_count: target_count,
                tags_filter: tags_filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    createCustomMessageMulti(appids, batch_data, msg_type, content, tags_filter) {
        return new Promise( (resolve, reject) => {            
            request.post('mocha/custom_message', {
                appids: appids,
                batch_data: batch_data,
                msg_type: msg_type,
                content: content,
                tags_filter: tags_filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    createCustomMessageV2(appids, pub_datetime, msg_type, content, target_count, status, tags_filter) {
        return new Promise( (resolve, reject) => {            
            request.post('mocha/custom_message', {
                appids: appids,
                pub_datetime: pub_datetime,
                msg_type: msg_type,
                content: content,
                target_count: target_count,
                pub_status: status,
                tags_filter: tags_filter,
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    getCustomMessages(filters) {
        return new Promise( (resolve, reject) => {            
            request.get('mocha/custom_message', {
                filters: filters
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    deleteCustomMessages(msg_id) {
        return new Promise( (resolve, reject) => {            
            request.delete('mocha/custom_message', {msg_id: msg_id}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    auditCustomMessages(msg_id) {
        return new Promise( (resolve, reject) => {            
            request.put('mocha/custom_message', {msg_id: msg_id}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    get_done(msg_id) {
        return new Promise( (resolve, reject) => {            
            request.get('mocha/custom_message/current_done', {
                msg_id: msg_id
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    get_result(msg_id) {
        return new Promise( (resolve, reject) => {            
            request.get('mocha/custom_message/result', {
                msg_id: msg_id
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    pre_view(data) {
        return new Promise( (resolve, reject) => {            
            request.post('mocha/custom_message/preview', data).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    get_summary(tags_filter) {
        return new Promise( (resolve, reject) => {            
            request.get('mocha/custom_message_new/summary', { tags_filter: tags_filter}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
}