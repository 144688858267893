"use strict";
import axios from 'axios';
import request from './request'
import utils from '~/utils/'

export default {
    getAppData(appid) {
        return request.get('appdata', {
            appid: appid,
            type: "appdata"
        });
    }
}