"use strict";
import axios from 'axios';
import request from './request'

export default {
    getNewsTitleRanking(filter) {
        return new Promise((resolve, reject) => {
            request.get('news/title_rank', {
                'filter': filter
            }).then(data => {
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    updateRecord(info) {
        return new Promise((resolve, reject) => {
            request.put('news/title_rank', info).then(data => {
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    }
}