"use strict";
import axios from 'axios';
import user from './user.js'

export default {
    runCodes(env, appid, codes) {
        console.log(user.info);        
        let params = {
            'user': {
                openid: user.info.uid,
                nickname: user.info.nickname,
                avatarUrl: user.info.headimgurl
            },
            'codes': codes
        }
        return new Promise( (resolve, reject) => {
            let server;
            
            
            
            if(env == 'test'){
                server = 'https://api.moapp.mogoboom.com/moapp/debug/'
            }else if(env == 'release'){
                server = 'https://api.moapp.mogoboom.com/debug/';
            }else{
                server = 'https://dev.mozigu.net/moapp/debug/';
            }
            console.log(params)
            axios.post(server+appid, params).then( res => {
                if (res.data.ret == 0) {
                    resolve(res.data.data);
                } else {
                    reject(res.data);
                }
            }, err => {
                reject({
                    ret: -1,
                    msg: err,
                    data: ''
                })
            });
        }); // Promise
    }
}