"use strict";
import axios from 'axios';
import request from './request'

import ProductQuery from './mocha_product/product_query'
import ProductInfo from './mocha_product/product_info'
import MpQuery from './mocha_product/mp_query'
import ProductManage from './mocha_product/product_manage'
import QrcodeLog from './mocha_product/qrcode_log'
import RandomQrcode from './mocha_product/random_qrcode'
import MpDisablePlan from "./mocha_product/mp_disable_plan"

var ProductMgr = {
    getProductInfo(filter){
        return new Promise( (resolve, reject) => {
            request.get('product_manager', {
                'filter': filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    getProductTargetMp(filter){
        return new Promise( (resolve, reject) => {
            request.get('product_manager_app/target_mp', {
                'filter': filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    addProduct(data){
        return new Promise( (resolve, reject) => {
            request.post('product_manager', {
                'data': data
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    changeProduct(filters){
        return new Promise( (resolve, reject) => {
            request.put('product_manager', {
                'filters': filters
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    deleteProduct(data){
        return new Promise( (resolve, reject) => {
            request.delete('product_manager', {
                'data': data
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    getAppName(filter){
        return new Promise( (resolve, reject) => {
            request.get('product_manager_app/appname', {
                'filter': filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    getSingleProductInfo(filter){
        return new Promise( (resolve, reject) => {
            request.get('product_manager_app/product_info', {
                'filter': filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    getSingleProductStrategy(filter){
        return new Promise( (resolve, reject) => {
            request.get('product_manager_app/product_strategy', {
                'filter': filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    getMpStrategyAndFans(mps){
        return new Promise( (resolve, reject) => {
            request.get('product_manager_app/mp_strategy_fans', {
                'mps': {'mps':mps}
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    getSingleProductDataflow(filter){
        return new Promise( (resolve, reject) => {
            request.get('product_manager_app/product_dataflow', {
                'filter': filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    getApps(filter){
        return new Promise( (resolve, reject) => {
            request.get('product_manager_app/apps', {
                'filter': filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    getLogs(filter){
        return new Promise( (resolve, reject) => {
            request.get('product_manager_log/logs', {
                'filter': filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    getImage(filter){
        return new Promise( (resolve, reject) => {
            request.get('product_manager_app/image', {
                'filter': filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    getValidInfoForMps(filter){
        return new Promise( (resolve, reject) => {
            request.get('product_manager_app/mps_config_check', {
                'filter': filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    getExtraInfo(filter){
        return new Promise( (resolve, reject) => {
            request.get('product_manager_app/extra', {
                'filter': filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    setMpActive(filter){
        return new Promise( (resolve, reject) => {
            request.put('product_manager_app/mp_active', {
                'filter': filter,
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    getProductLazyData(filter){
        return new Promise( (resolve, reject) => {
            request.get('product_manager_app/index_lazy', {
                'filter': filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    getMpsOwnership(filter){
        return new Promise( (resolve, reject) => {
            request.get('product_manager_app/mps_ownership', {
                'filter': filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    // 获取产品公号的涨粉统计数据
    getMpsFansStatistics(filter){
        return new Promise( (resolve, reject) => {
            request.get('product_manager_app/mps_fans_statistics', {
                'filter': filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    // 获取上一次添加自增长公众号，是否处于总涨粉超过100的状态
    getLastTargetMpAddState(filter){
        return new Promise( (resolve, reject) => {
            request.get('product/mp/target_mp_last_add_state', {
                'filter': filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    // 获取对某个产品的访问权限
    get_product_access_power(name_en){
        return new Promise( (resolve, reject) => {
            request.get('product/query/access/edit_permission?name_en='+name_en, {}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    getLatestResults(author, name) {
        return new Promise( (resolve, reject) => {
            request.get('product_manager_app/latest_results', {
                'author': author,
                'name': name
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    }
}

const protoes = [
    ProductQuery,
    ProductManage,
    ProductInfo,
    QrcodeLog,
    MpQuery,
    RandomQrcode,
    MpDisablePlan,
]

protoes.forEach( item =>{
    for(var j in item){
        if(ProductMgr[j] == undefined){
            ProductMgr[j] = item[j];
        }
    }
})

export default ProductMgr;