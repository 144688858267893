"use strict";
import axios from 'axios';
import request from './request'
import utils from '~/utils/'

export default {
    find(page, size, filters) {
        return request.get('ad/list', {
            page, 
            size,
            filters
        });
    },
    add_one(info){
        return new Promise((resolve, reject) => {
            request.post('ad/one',info).then(data => {
                resolve(data);
            }, err => {
                reject(err);
            });
        });
    },
    delete_one(query){
        return new Promise((resolve, reject) => {
            request.delete('ad/one',query).then(data => {
                resolve(data);
            }, err => {
                reject(err);
            });
        });
    },
    find_one(query){
        return new Promise((resolve, reject) => {
            request.get('ad/one', {query}).then(data => {
                resolve(data);
            }, err => {
                reject(err);
            });
        });
    },
    update_one(info){
        return new Promise((resolve, reject) => {
            request.put('ad/one', info).then(data => {
                resolve(data);
            }, err => {
                reject(err);
            });
        });
    },
}