"use strict";
import request from './request'

export default {
   
    getforbiddenData(filter){
            return new Promise( (resolve, reject) => {
                console.log('filter',filter);
                request.get('api_forbidden', {
                    'filter':  filter                                      
                }).then( data=>{
                    resolve(data);
                }, err => {
                    reject(err)
                });
            });
        },
}