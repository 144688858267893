'use strict';
import utils from '../utils';
import request from './request';
import { default as mooa } from '@mzg/mooa';
export default {
    info: {},
    login(targetUrl) {
        let realUrl = encodeURIComponent(targetUrl); // 页面的url
        // 授权成功后给到的服务器请求
        let moadminSessionUrl = encodeURIComponent('http://mozigu.cn/api/login?path=' + realUrl);
        let url = encodeURIComponent(`http://mozigu.cn/api/wxauth?redirect=${moadminSessionUrl}`);
        // 微信登录授权回调

        let auth_url = '';
        if (utils.inWeixin()) {
            // let appid = 'wx88acc94e3b3d2bce'; // About U
            let appid = 'wx9aa95fff5a8c46dd'; // 墨子谷
            auth_url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=${appid}#wechat_redirect`;
        } else {
            let appid = 'wx0db6fdb4a0d3cfbe'; // 墨子谷开放平台
            auth_url = `https://open.weixin.qq.com/connect/qrconnect?appid=${appid}&redirect_uri=${url}&response_type=code&scope=snsapi_login&state=${appid}#wechat_redirect`;
        }

        window.location.href = auth_url;
    },
    // login() {
    //     return new Promise((resolve, reject) => {
    //         mooa.login({
    //             skipLoginPage: false, // 跳过组件提供的登录页面
    //             success: (res) => {
    //                 console.log(res);
    //                 resolve();
    //             },
    //             fail: reject
    //         });
    //     });
    // },
    getuserInfo() {
        return new Promise((resolve, reject) => {
            mooa.getSession({
                success: resolve,
                fail: reject
            });
        });
    },
    logout() {
        utils.setCookie('session', '', 0);
        location.reload();
    },
    checkSession(session) {
        return new Promise((resolve, reject) => {
            request.get('user/check', {}).then(
                (data) => {
                    resolve(data);
                },
                (err) => {
                    console.error('user/check fail', err);
                    resolve({});
                    //reject(err);
                }
            );
        });
    },
    checkLogin(options) {
        // let session = mooa.getSessionId();
        // if (session) {
        //     utils.setCookie('session_valid', '1', 1);
        //     // utils.setCookie('MOOA-SESSION', session, 1);
        //     this.getuserInfo().then(res => {
        //         let {nickname,headimgurl,uid} = res
        //         console.log(res);
        //         utils.setCookie('nickname', nickname, 1);
        //         utils.setCookie('avatarUrl', headimgurl, 1);
        //         utils.setCookie('uid', uid, 1);
        //         this.info={
        //             nickname,
        //             avatarUrl:headimgurl,
        //             uid
        //         }
        //         this.checkSession()
        //     })
        //     options.successed();
        // } else {
        //     options.failed();
        // }

        let session = utils.getCookie('session');
        if (session != null && session != '') {
            if (utils.getCookie('session_valid') == '1') {
                options.successed();
            } else {
                this.checkSession()
                    .then(() => {
                        this.info = {
                            nickname: decodeURI(escape(utils.getCookie('nickname'))),
                            headimgurl: decodeURI(utils.getCookie('avatarUrl')),
                            uid: utils.getCookie('uid')
                        };

                        utils.setCookie('session_valid', '1', 1);
                        options.successed();
                    })
                    .catch((err) => {
                        console.error('checkSession fail, err:', err);
                    });
            }
        } else {
            options.failed();
        }
    },
    setData(module, key, value) {
        return new Promise((resolve, reject) => {
            if (module && key) {
                request
                    .put(`user/data/${module}/${key}`, {
                        value: value
                    })
                    .then(
                        (data) => {
                            resolve(data);
                        },
                        (err) => {
                            reject(err);
                        }
                    );
            } else {
                let msg = 'invalid setData params';
                console.error(msg);
                reject(msg);
            }
        });
    },
    getData(module, key, default_value = null) {
        return new Promise((resolve, reject) => {
            request.get(`user/data/${module}/${key}`, {}).then(
                (data) => {
                    if (data != null) {
                        resolve(data);
                    } else {
                        resolve(default_value);
                    }
                },
                (err) => {
                    reject(err);
                }
            );
        });
    },
    getToken() {
        return mooa.getSessionId();
    },
    delData(module, key) {
        let k = `${module}-${key}`;
        localStorage.removeItem(k);
    },
    editInfo(post_data) {
        return new Promise((resolve, reject) => {
            console.log(post_data);
            request
                .put('user/edit_info', {
                    post_data: post_data
                })
                .then(
                    (data) => {
                        console.log(data);
                        resolve(data);
                    },
                    (err) => {
                        reject(err);
                    }
                );
        });
    },
    getInfo() {
        return new Promise((resolve, reject) => {
            request.get('user/edit_info', {}).then(
                (data) => {
                    resolve(data);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    },
    getStatus() {
        return new Promise((resolve, reject) => {
            // 避免浏览器磁盘缓存或者内存缓存导致输入手机验证码以后，状态校验失败，以至于一直循环登陆
            // 以秒为单位的时间戳，避免1s以内发送多个请求
            let t = Math.floor(new Date().getTime() / 1000);
            request.get('user/user_info?t=' + t, {}).then(
                (data) => {
                    resolve(data);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    },
    getCode(filter) {
        return new Promise((resolve, reject) => {
            console.log(filter);
            request
                .get('user/get_code', {
                    filter: filter
                })
                .then(
                    (data) => {
                        resolve(data);
                    },
                    (err) => {
                        reject(err);
                    }
                );
        });
    },
    getInfoByUid(uid) {
        return request.get('user/v2/' + uid);
    }
};
