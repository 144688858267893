export default {
  path: '/mps',
  title: '公众号',
  component: resolve => require(['~/views/frame/frame.vue'], resolve),
  redirect: '/mps/home',
  meta: {
    title: '首页',
    keepAlive: false,
  },
  children: [
    {
      path: '/mps/home',
      meta: {
        title: '首页',
        keepAlive: false
      },
      menu: {
          icon: 'fa fa-home',
          pos: 0
      },
      component: resolve => require(['~/views/mps/home/index.vue'], resolve),
    },
    {
      path: '/mps/menu',
      meta: {
        title: '菜单管理',
        keepAlive: false
      },
      menu: {            
          icon: 'fa fa-bars',
          pos: 1
      },
      component: resolve => require(['~/views/mps/menu.vue'], resolve),
    },
    {
      path: '/mps/conditional_menu',
      meta: {
        title: '个性化菜单',
        keepAlive: false
      },
      menu: {
        icon: 'fa fa-bars',
        pos: 2
      },
      component: resolve => require(['~/views/mps/conditional_menu.vue'], resolve),
    },
    {
      path: '/mps/code',
      meta: {
        title: '代码管理',
        keepAlive: false
      },
      menu: {
        icon: 'fa fa-code',
        pos: 3
      },
      component: resolve => require(['~/views/mps/code.vue'], resolve),
    },
    // {
    //   path: '/mps/user_tag',
    //   meta: {
    //     title: '用户标签',
    //     keepAlive: false
    //   },
    //   menu: {
    //     icon: 'fa fa-tags',
    //     pos: 5
    //   },
    //   component: resolve => require(['~/views/mps/user_tag.vue'], resolve),

    // },
    // {
    //   path: '/mps/param_qrcode',
    //   meta: {
    //     title: '参数二维码',
    //     keepAlive: false
    //   },
    //   menu: {
    //     icon: 'fa fa-clock-o',
    //     pos: 6
    //   },
    //   component: resolve => require(['~/views/mps/param_qrcode.vue'], resolve),

    // },
    // {
    //   path: '/mps/introduction',
    //   meta: {
    //     title: '视频教程',
    //     keepAlive: false
    //   },
    //   menu: {
    //     // icon: 'fa fa-safari',
    //     icon:"fa fa-map-signs",
    //     pos: 8
    //   },
    //   component: resolve => require(['~/views/mps/introduction.vue'], resolve),

    // },
    // {
    //   path: '/mps/sub_his',
    //   meta: {
    //     title: '粉丝来源',
    //     keepAlive: false
    //   },
    //   menu: {
    //     // icon: 'fa fa-safari',
    //     icon:"fa fa-user-o",
    //     pos: 9
    //   },
    //   component: resolve => require(['~/views/mps/sub_his.vue'], resolve),
    // },
    // {
    //   path: '/mps/mp_user',
    //   meta: {
    //     title: '粉丝数据',
    //     keepAlive: false
    //   },
    //   menu: {
    //     // icon: 'fa fa-safari',
    //     icon:"fa fa-user-o",
    //     pos: 9
    //   },
    //   component: resolve => require(['~/views/mps/mp_user.vue'], resolve),
    // },
  ]
}