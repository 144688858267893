"use strict";
import axios from 'axios';
import util from "~/utils/index.js";
import { Request } from "./request.js";

/*
    获取跟 mox video 处理相关的接口
*/

const MoxVideoRequest = new Request("http://video.mozigu.cn/api/")

export default {

    get_videos(){
        var uid = util.getCookie('uid');
        return MoxVideoRequest.get('me',{uid: uid});
    },
    get_video_json(vid){
        return MoxVideoRequest.get('barrage_json', {vid:vid})
    },
    upload_video(files,args) {
        var uid = util.getCookie('uid');
        var formData = new FormData();
        formData.append('file', files);
        formData.append('args', JSON.stringify(args));
        return MoxVideoRequest.post('upload?uid='+uid,  formData)
    },
    save_barrage(vid, video_short_id, barrage){
        return MoxVideoRequest.post('barrage/save', {barrage: barrage, video_short_id: video_short_id,vid: vid})
    },
    make_video(vid, video_short_id){
        var uid = util.getCookie('uid');
        return MoxVideoRequest.post('make', {vid: vid, video_short_id: video_short_id, uid: uid})
    },
    get_video_status(vid){
        return MoxVideoRequest.get('status', {vid: vid})
    },
    delete_video(vid){
        var uid = util.getCookie('uid');
        return MoxVideoRequest.delete('delete', {vid: vid, uid: uid})
    },
    update_video_title(vid, title){
        return MoxVideoRequest.post('info', {vid: vid, title:title})
    },
    update_video_subtitle_global_style(vid, subtitle_global_style){
        return MoxVideoRequest.post('info', {vid: vid, subtitle_global_style:subtitle_global_style})
    },
    get_font_list(){
        return MoxVideoRequest.get('font_info', {'filter': {}})
    },
}
