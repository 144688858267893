//const url = __DEV__ ? devUrl : proUrl
const dev_app = 'mox5e6ee02693193b1ba1d1ace9'
const pro_app = 'mox5e415a35ab028fc94d7d9a5b'

const config = {
    siteName: '墨子谷',
    apiHost: '/api/',
    appid: process.env.NODE_ENV === 'development' ? dev_app : pro_app
}

export default config;
