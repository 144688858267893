"use strict";
/*
    微信公众号客服消息相关接口
*/
import axios from 'axios';
import request from './request'
import utils from '~/utils/'

export default {
    /*
        获取当前能使用客服消息的产品经理列表
    参数:
        filters:检索条件,{type|object}
    */
    get_custom_message_pm(fitlters) {
        var data = {
            "fitlters":fitlters
        }
        return new Promise( (resolve, reject) => {            
            request.get('mocha/custom_message/manage/pm', data).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    /*
        新增能使用客服消息的产品经理
    参数:
        uid:产品经理id,{type|string}
    */
    add_custom_message_pm(pms) {
        var data = {
            'pms':pms
        }
        return new Promise( (resolve, reject) => {            
            request.post('mocha/custom_message/manage/pm', data).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    /*
    删除能使用客服消息的产品经理
    参数:
        uid:产品经理id,{type|string}
    */

    delete_custom_message_pm(pmid) {
        var data = {
            "pmid":pmid
        }
        return new Promise( (resolve, reject) => {
            console.log(data)      
            request.delete('mocha/custom_message/manage/pm', data).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },


    /*
        修改能使用客服消息的产品经理
    参数:
        uid:产品经理id,{type|string}
        data:修改信息，{type|object}
    */
    change_custom_message_pm(pmid,pmdata) {
        var data = {
            "pmid":pmid,
            "pmdata":pmdata
        }
        return new Promise( (resolve, reject) => {
            request.put('mocha/custom_message/manage/pm', data).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    /*
        获取能使用客服消息的公众号列表
    参数:
        filters:检索条件,{type|object}
    */
    get_custom_message_mps(data) {
        return new Promise( (resolve, reject) => {            
            request.get('mocha/custom_message/manage/mps', data).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    /*
        新增能使用客服消息的公众号
    参数:
        mps:公众号列表，{type:array}
        [
            {
                "mpid":公众号id
            }
            ...
        ]
    */
    add_custom_message_mps(mps) {
        var data = {
            "mps":mps
        }
        return new Promise( (resolve, reject) => {            
            request.post('mocha/custom_message/manage/mps', data).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    
    /*
        删除能使用客服消息的公众号
    参数:
        mpid:公众号id，{type:string}
    */

    delete_custom_message_mp(mpid) {
        var data = {
            "mpid":mpid
        }
        return new Promise( (resolve, reject) => {            
            request.delete('mocha/custom_message/manage/mps', data).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    /*
        检查用户权限
    参数:
        无
    */
    check_permission() {
        var data = {}
        return new Promise( (resolve, reject) => {
            request.get('mocha/custom_message/manage/permission/check', data).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
}