export default {
    /* 获取指定名称的cookie*/
    getCookie(name) {
        var reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)');

        var arr = document.cookie.match(reg);
        if (arr) { return unescape(arr[2]); } else { return null; }
    },
    setCookie(name, value, expiresHours) {
        var cookieString = name + '=' + escape(value);
        if (expiresHours > 0) {
            var date = new Date();
            date.setTime(date.getTime() + expiresHours * 3600 * 1000);
            cookieString = cookieString + '; expires=' + date.toGMTString();
        }
        document.cookie = cookieString;
    },
    getUrlParam(key) {
        const url = location.href;

        const pos = url.indexOf('?');
        if (pos !== -1) {
            const sharpPos = url.indexOf('#', pos + 1);
            let length = url.length - pos;

            if (sharpPos !== -1) {
                length = sharpPos - pos - 1;
            }

            const params = url.substr(pos + 1, length);
            const items = params.split('&');
            for (var i = 0; i < items.length; i++) {
                const values = items[i].split('=');
                if (values.length === 2) {
                    if (values[0] === key) {
                        return values[1];
                    }
                }
            }
        }

        return null;
    },
    toZero(data) {
        return data >= 10 ? data : '0' + data;
    },
    formatFullTime(time) {
        const date = new Date(time);
        let y = date.getFullYear();
        let m = date.getMonth() + 1;
        return `${y}-${this.toZero(m)}`;
    },
    jsonToUrlParams(json_data) {
        let params = '';
        for (var k in json_data || {}) {
            params += '&' + k + '=' + encodeURIComponent(json_data[k]);
        }

        return params.length === 0 ? '' : params.slice(1, params.length);
    },
    // 获得url中#参数的值，如: http://mozigu.cn/#/apps/create#step=1，获取step的值
    getUrlSharp(key) {
        const reg = new RegExp('(#)' + key + '=([^#]*)(#|$)', 'i');
        const r = window.location.href.match(reg);
        if (r != null) {
            return r[2];
        }

        return null;
    },
    // 设置url中#参数的值，如: http://mozigu.cn/#/apps/create#step=1
    setUrlSharp(key, value) {
        const reg = new RegExp('(#)' + key + '=([^#]*)(#|$)', 'i');
        const r = window.location.href.match(reg);
        const keys = key + '=' + value;

        if (r !== null) {
            location.href = location.href.replace(reg, '#' + keys);
        } else {
            location.href = location.href + '#' + keys;
        }

        return null;
    },
    isValidUrl(url) {
        if (url !== '') {
            var reg = /^(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/;

            return reg.test(url);
        } else {
            return false;
        }
    },
    isInt(string) {
        var reg = /^[0-9]+.?[0-9]*$/;
        return reg.test(string);
    },
    parseTokens(rawValue) {
        /*
          多输入分析,以 空格|中文逗号|英文逗号|中文分号|英文分号 作为分隔字符进行拆分

          参数说明:
              rawValue:用户输入的字符串

          使用场景:
              多关键字检索,以 空格|中文逗号|英文逗号|中文分号|英文分号 作为分隔符
        */
        var sep = /(,|，|;|；|(\s+))+/g;
        var tokens = rawValue.split(sep).filter((val, index) => {
            // 过滤掉 空分词|空字符串|分隔字符
            return !!(val && val.trim().length > 0 && sep.test(val) === false);
        });
        return tokens;
    },
    isValidTimeString(time_string) {
        let ret = false;
        if (time_string && typeof (time_string) === 'string') {
            if (time_string.length === 4) {
                const hour = parseInt(time_string[0] + time_string[1]);
                const min = parseInt(time_string[2] + time_string[3]);

                ret = (hour >= 0 && hour < 24 && min >= 0 && min < 60);
            }
        }

        return ret;
    },
    // 克隆对象
    clone(obj) {
        var ret, k, b;
        if ((b = (obj instanceof Array)) || obj instanceof Object) {
            ret = b ? [] : {};
            for (k in obj) {
                if (obj[k] instanceof Array || obj[k] instanceof Object) { ret[k] = this.clone(obj[k]); } else {
                    ret[k] = obj[k];
                }
            }
        }

        return ret;
    },
    inWeixin() {
        var ua = navigator.userAgent.toLowerCase();
        return ua.match(/MicroMessenger/i) !== null
    },
    formatFileSize(size) {
        if (size >= 1024 && size < 1024 * 1024) {
            return (size / 1024.0).toFixed(1) + ' KB';
        } else if (size >= 1024 * 1024 && size < 1024 * 1024 * 1024) {
            return (size / (1024 * 1024.0)).toFixed(1) + ' MB';
        } else {
            return size + ' B';
        }
    },
    isPC() {
        var userAgentInfo = navigator.userAgent;
        var Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
        var flag = true;
        for (var v = 0; v < Agents.length; v++) {
            if (userAgentInfo.indexOf(Agents[v]) > 0) {
                flag = false;
                break;
            }
        }
        return flag;
    },
    is_on_laptop() {
        // 是否在笔记本中
        var laptop = false;
        if (window.screen.width > 900 && window.screen.width <= 1400) {
            laptop = true;
        }
        return laptop;
    },
    initViewport() {
        if (!this.isPC()) {
            document.write('<meta name="viewport" content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0">'); // 0.75   0.82
        }
    },
    tableToExcel(jsonData, headers, title) {
        // 列标题，逗号隔开，每一个逗号就是隔开一个单元格
        let str = '';
        const headers_ch = [];
        const headers_en = [];
        headers.forEach(header => {
            headers_en.push(header[0]);
            headers_ch.push(header[1]);
        });
        str = headers_ch.join(',') + '\n';
        // 增加\t为了不让表格显示科学计数法或者其他格式
        for (let i = 0; i < jsonData.length; i++) {
            headers_en.forEach(item => {
                const content = jsonData[i][item];
                let value = '';
                if (typeof content === 'string') {
                    // 防止某些数据是没有某个字段的
                    value = content.replace(',', '，');
                } else if (content !== undefined && content !== null) {
                    value = content;
                }
                str += `${value + '\t'},`;
            });
            str += '\n';
        }
        // encodeURIComponent解决中文乱码
        const uri = 'data:text/csv;charset=utf-8,\ufeff' + encodeURIComponent(str);
        // 通过创建a标签实现
        var link = document.createElement('a');
        link.href = uri;
        // 对下载的文件命名
        link.download = title;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    },
    get_list(input) {
        const mps = input.split(/(?:[,;，；  ]+)/);
        let start = 0;
        let end = mps.length - 1;
        for (let i = 0; i < mps.length; i++) {
            const check_start = (mps[start] === '');
            const check_end = (mps[end] === '');
            if (check_start) {
                start += 1;
            }
            if (check_end) {
                end -= 1;
            }
            if ((!check_start) && (!check_end)) {
                break;
            }
        }
        return mps.splice(start, end - start + 1);
    },
    intersections(set_arr) {
        if (set_arr.length === 1) {
            return set_arr[0];
        }
        if (set_arr.length === 2) {
            return this.intersection(set_arr[0], set_arr[1]);
        }
        return this.intersection(
            this.intersections(set_arr.slice(0, set_arr.length - 1)),
            set_arr[set_arr.length - 1]
        );
    },
    intersection(thisSet, otherSet) {
        var interSectionSet = new Set();
        var values = Array.from(thisSet);
        for (var i = 0; i < values.length; i++) {
            if (otherSet.has(values[i])) {
                interSectionSet.add(values[i]);
            }
        }
        return interSectionSet;
    },
    deep_copy(data) {
        return JSON.parse(JSON.stringify(data));
    },
    zfill(value, length) {
        var target = (value + '');
        if (length > target.length) {
            target = new Array(length - target.length + 1).join('0').concat(target);
        }
        return target;
    },
    getTaskCounter(init, cb) {
        var counter = {
            'init': init,
            'release': function() {
                this.init--;
                if (this.init === 0) {
                    cb && cb();
                }
            }
        };
        return counter;
    },
    sortLikeWin(v1, v2) {
        var a = v1;
        var b = v2;
        var reg = /[0-9]+/g;
        var lista = a.match(reg);
        var listb = b.match(reg);
        if (!lista || !listb) {
            return a.localeCompare(b);
        }
        for (var i = 0, minLen = Math.min(lista.length, listb.length); i < minLen; i++) {
            // 数字所在位置序号
            var indexa = a.indexOf(lista[i]);
            var indexb = b.indexOf(listb[i]);
            // 数字前面的前缀
            var prefixa = a.substring(0, indexa);
            var prefixb = a.substring(0, indexb);
            // 数字的string
            var stra = lista[i];
            var strb = listb[i];
            // 数字的值
            var numa = parseInt(stra);
            var numb = parseInt(strb);
            // 如果数字的序号不等或前缀不等，属于前缀不同的情况，直接比较
            if (indexa !== indexb || prefixa !== prefixb) {
                return a.localeCompare(b);
            } else {
                // 数字的string全等
                if (stra === strb) {
                    // 如果是最后一个数字，比较数字的后缀
                    if (i === minLen - 1) {
                        return a.substring(indexa).localeCompare(b.substring(indexb));
                    }
                    // 如果不是最后一个数字，则循环跳转到下一个数字，并去掉前面相同的部分
                    else {
                        a = a.substring(indexa + stra.length);
                        b = b.substring(indexa + stra.length);
                    }
                }
                // 如果数字的string不全等，但值相等
                else if (numa === numb) {
                    // 直接比较数字前缀0的个数，多的更小
                    return strb.lastIndexOf(numb + '') - stra.lastIndexOf(numa + '');
                } else {
                    // 如果数字不等，直接比较数字大小
                    return numa - numb;
                }
            }
        }
    },
    createXMLHttpRequest() {
        var xmlHttp = null;
        xmlHttp = new XMLHttpRequest();
        return xmlHttp;
    },
    getLocaltimeOffsetUTC8East() {
        // 获取本地时间相对于东八区的时间偏移

        // GMT时间相对于东八区时间的偏移
        const GmtOffsetUTC8East = -480; // 以分钟为单位

        // GMT时间相对于客户端时间的偏移
        var GmtOffsetLocal = new Date().getTimezoneOffset(); // 以分钟为单位

        // 客户端时间相对于东八区时间的偏移
        var localtimeOffsetUTC8East = (GmtOffsetUTC8East - GmtOffsetLocal) * 60; // 以秒为单位

        return localtimeOffsetUTC8East;
    },

    UTC8EastConvert2Localtime(timestamp) {
        // 将东八区的时间戳转换为客户端所在时区的时间戳
        var localtimeOffsetUTC8East = this.getLocaltimeOffsetUTC8East();
        return timestamp - localtimeOffsetUTC8East;
    },
    localtimeConvert2UTC8East(timestamp) {
        // 将客户端所在时区的时间戳转换为东八区的时间戳
        var localtimeOffsetUTC8East = this.getLocaltimeOffsetUTC8East();
        return timestamp + localtimeOffsetUTC8East;
    },
    get_random_string(len) {
        len = len || 32;
        var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'; /** **默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
        var maxPos = $chars.length;
        var pwd = '';
        for (var i = 0; i < len; i++) {
            pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
        }
        return pwd;
    },
    formatNumber(num) {
        if (num < 10000) {
            return num + '';
        } else {
            return (num / 10000.0).toFixed(2).replace(/0+$/, '') + 'w';
        }
    }
};

Date.prototype.Format = function(fmt) { // author: meizz
    var o = {
        'M+': this.getMonth() + 1, // 月份
        'd+': this.getDate(), // 日
        'h+': this.getHours(), // 小时
        'm+': this.getMinutes(), // 分
        's+': this.getSeconds(), // 秒
        'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
        'S': this.getMilliseconds() // 毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length));
    for (var k in o) { if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length))); }
    return fmt;
};

Date.prototype.isSameDay = function(d2) {
    return this.getFullYear() == d2.getFullYear() && this.getMonth() == d2.getMonth() && this.getDate() == d2.getDate();
};

Date.prototype.setTimeWithinToday = function(hours, minutes, seconds, milliseconds) {
    this.setHours(hours);
    this.setMinutes(minutes);
    this.setSeconds(seconds);
    this.setMilliseconds(milliseconds);
};

Date.prototype.getTimestampBySeconds = function() {
    // 获取以秒为单位的时间戳
    return parseInt(this.getTime() / 1000);
};

Array.prototype.getIntersect = function(another) {
    /*
      获取 与 另外一个数组的交集

      参数说明:
          another:另外一个数组
    */
    let intersect = [];
    if (another && Array.isArray(another)) {
        intersect = this.filter(item => another.indexOf(item) != -1);
    }
    return intersect;
};

Array.prototype.hasIntersect = function(another) {
    /*
      判断与另外一个数组是否有交集

      参数说明:
          another:另外一个数组
    */

    return this.getIntersect(another).length > 0;
};

Array.prototype.sortByProperty = function(prop, reverse) {
    /*
      按照某个属性排序

      参数说明：
        prop:字符串，属性名
        reverse:是否需要逆序，传入true时有效
    */

    this.sort((a, b) => {
        return a[prop] - b[prop];
    });

    if (reverse === true) {
        this.reverse();
    }

    return this;
};

Object.defineProperty(Array.prototype, 'sortByProperty', { 'enumerable': false });
Object.defineProperty(Array.prototype, 'getIntersect', { 'enumerable': false });
Object.defineProperty(Array.prototype, 'hasIntersect', { 'enumerable': false });
