import user from './user';
import apps from './apps';
import database from './database';
import cms from './cms';
import appdata from './appdata';
import memdata from './memdata';
import dataadmin from './data_admin';
import news from './news';
import commit_log from './commit_log';
import domain from './domain';
import product_data from './product_data';
import mp_user_tags from './mp_user_tags';
import read_data from './read_data';
import box from './box';
import mpdata_forbid from './mpdata_forbid';
import moapp_debugger from './moapp_debugger';
import data_stat from './data_stat';
import mpdata_menu from './mpdata_menu';
import phrasebook from './phrasebook';
import tutorial from './tutorial';
import mp_tags_mgr from './mp_tags_mgr';
import assets from './assets';
import product_mgr from './product_manager';
import mocha_dashboard from './mocha_dashboard';
import monitor_data from './monitor_data';
import code_project from './code_project';
import component_market from './component_market';
import moapp_market from './moapp_market';
import default_material from './default_material';
import pay_bill_mgr from './pay_bill_mgr';
import tools from './tools';
import lingji from './lingji';
import astrology from './astrology';
import user_admin from './user_admin';
import mocha_custom_message from './mocha_custom_message';
import mocha_web_error from './mocha_web_error';
import mobile_preview from './mobile_preview';
import material_category from './material_category';
import resume from './resume';
import article_lib from './article_lib';
import mox_video from './mox_video';
import mp_fans_history from './mp_fans_history';
import mocha_mp_message from './mocha_mp_message';
import uri_group from './uri_group';
import mocha_cron from './mocha_cron';
import make_img_times from './make_img_times';
import params_qrcode from './params_qrcode';
import user_tag from './user_tag';
import mocha_custom_message_mgr from './mocha_custom_message_mgr';
import mp_admin from './mp_admin';
import product_test from './product_test';
import mp_pd from './mp_pd';
import template_msg_push from './template_msg_push';
import user_taggle from './user_taggle';
import short_url from './short_url';
import mox_order from './mox_order';
import sign from './sign';
import news_title_ranking from './news_title_ranking';
import ad from './ad';
import mp_user from './mp_user';
import mp_tags_daily_stat from './mp_tags_daily_stat';
import constellation_article_data from './constellation_article_data';
import miniprogram from './miniprogram/miniprogram';

export default {
    sign,
    short_url,
    user_taggle,
    mocha_custom_message_mgr,
    user,
    apps,
    database,
    cms,
    appdata,
    memdata,
    dataadmin,
    news,
    commit_log,
    domain,
    product_data,
    mp_user_tags,
    read_data,
    box: box,
    mpdata_forbid,
    moapp_debugger,
    data_stat,
    mpdata_menu,
    phrasebook,
    tutorial,
    mp_tags_mgr,
    assets: assets,
    product_mgr,
    mocha_dashboard,
    monitor_data,
    code_project,
    component_market,
    moapp_market,
    default_material,
    pay_bill_mgr,
    astrology,
    tools,
    user_admin,
    mocha_custom_message,
    mocha_web_error,
    mobile_preview,
    lingji,
    material_category,
    resume,
    article_lib,
    mox_video,
    mp_fans_history,
    mocha_mp_message,
    uri_group,
    mocha_cron,
    make_img_times,
    params_qrcode,
    user_tag,
    mp_admin,
    product_test,
    mox_order,
    mp_pd,
    news_title_ranking,
    ad,
    mp_user,
    mp_tags_daily_stat,
    constellation_article_data,
    miniprogram,
    template_msg_push
};
