"use strict";
import request from './request'

export default {
    getMpMes(filter, page_range){
        return new Promise( (resolve, reject) => {
            request.get('mocha/mp_message', {
                'filter': filter,
                'page_index': JSON.stringify(page_range)
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    addMpMes(data) {
        return new Promise( (resolve, reject) => {
            request.post('mocha/mp_message', data).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    updateMpMes(data) {
        return new Promise( (resolve, reject) => {
            request.put('mocha/mp_message', data).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    deleteMpMes(id) {
        return new Promise( (resolve, reject) => {
            request.delete('mocha/mp_message', {message_id: id}).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    getServiceMps(){
        return new Promise( (resolve, reject) => {
            request.get('news/service_mps', {
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    createQrcode(params){
        return new Promise( (resolve, reject) => {
            request.post('mocha/mp_qrcode', params).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
    getQrcodeRecords(filters) {
        return request.get('mocha/mp_qrcode/records', filters);
    }
}
