"use strict";
import request from './request'

export default {
    updateMpInfo(id,info){
        /*
            修改封禁公众号信息
         */
        return request.put(`mpdata_forbid?id=${id}`, info);
    },
    getData(filter){
        return new Promise( (resolve, reject) => {
            request.get('mpdata_forbid', {
                'filter': filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    }
}