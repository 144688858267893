"use strict";
import axios from 'axios';
import request from './request'

export default {
    getMoAppCodeInstance(filter){
        return new Promise( (resolve, reject) => {
            request.get('turorialCodeApi', {
                'filter': filter
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    addMoAppCodeInstance(data){
        return new Promise( (resolve, reject) => {
            request.post('turorialCodeApi', {
                'data': data
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    changeMoAppCodeInstance(data){
        return new Promise( (resolve, reject) => {
            request.put('turorialCodeApi', {
                'data': data
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    deleteMoAppCodeInstance(data){
        return new Promise( (resolve, reject) => {
            request.delete('turorialCodeApi', {
                'data': data
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
}