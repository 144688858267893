"use strict";
import axios from 'axios';
import request from '../request'

export default {

    // 获取某个产品下的某个号的定时打零任务
    get_mp_disable_plan(mpid,name_en){
        return new Promise( (resolve, reject) => {
            request.get('product/mp/disable/plan', {
                "mpid":mpid,
                "name_en":name_en
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },

    // 修改某个产品下的某个号的定时打零任务
    change_mp_disable_plan(mpid,name_en,data){
        return new Promise( (resolve, reject) => {
            request.post('product/mp/disable/plan', {
                "mpid":mpid,
                "name_en":name_en,
                "data":data
            }).then( data=>{
                resolve(data);
            }, err => {
                reject(err)
            });
        });
    },
}